import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Container, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

import { useMenuOpenData, useLanguageData } from "../../contexts/Contexts";
import { configureRoute, getImageUrl, getStrapiEndpoints } from "../../utils/functions";
import { popUpAnimationMenu } from "../../utils/animations";
import { useStrapiLocale } from "../../hooks/hooks";
import Links from "./Links";

import { alignItemsCenter, flexRow, footerLocaleContainer, menuButtonText, 
  menuCloseButton, menuContainerMobile, menuLink, menuLinksContainerMobile, 
  menuLogo2 } from "../../theme/styles";


export default function MobileMenu({data, radioDefault, radioHover, option, logo, textClass, isDark, handleLocale}) {
  const [locales, setLocales] = useState([]);

  const theme = useTheme()
  const menuContext = useMenuOpenData()
  const { languageSelected } = useLanguageData()
  const location = useLocation()
  const localeStrapi = useStrapiLocale(getStrapiEndpoints('locale'));

  useEffect(() => {
    setLocales(localeStrapi);
  }, [localeStrapi]);

  return(
    <AnimatePresence>
      {data && menuContext.menuOpen &&
        <Container maxWidth={false} 
          sx={[menuContainerMobile, {background: theme.palette[option === 'hero' ? isDark ? 'bluetec' : 'whitec' : 'whitec']}]}
          onClick={() => {menuContext.toggleMenu()}}
          component={motion.div}
          initial={popUpAnimationMenu.hidden}
          animate={popUpAnimationMenu.visible}
          exit={popUpAnimationMenu.hidden}
          transition={popUpAnimationMenu.transition}
        >
          <Container disableGutters maxWidth={false} sx={menuLinksContainerMobile}>
            <Button 
              onClick={() => {menuContext.toggleMenu()}} 
              sx={menuCloseButton}>
              <Typography sx={[textClass, {[theme.breakpoints.down('l')]: {fontSize: '28px'}}]}>{'X'}</Typography>
            </Button>
            <Link to={configureRoute('/', languageSelected)}style={{width: '100%', textAlign: 'center'}}>
              <img style={menuLogo2} alt={''} src={getImageUrl(logo)} />
            </Link>
            {data.routes?.data?.map((route, i) => (
              route.attributes &&
              <Links
                key={i}
                to={route.attributes.route}
                name={route.attributes.name}
                isActive={location.pathname === configureRoute(route.attributes.route, languageSelected)}
                textClass={textClass}
                radioDefault={radioDefault}
                radioHover={radioHover}
              />
            ))}
            <Container disableGutters maxWidth={false} sx={footerLocaleContainer}>
              {locales &&
                locales.map((l, i) => (
                  l &&
                  <Container disableGutters key={i} onClick={() => {handleLocale(l.code)}} sx={[flexRow, alignItemsCenter, menuLink, {zIndex: 4, cursor: 'pointer', padding: 'auto'}]}>
                    <Typography sx={[menuButtonText, {color: theme.palette[option === 'hero' ? isDark ? 'whitec' : 'bluetec' : 'bluetec']}]}>
                      <span style={l.code === languageSelected ? {fontWeight: 700} : {}}>
                        {l.code}
                      </span>
                    </Typography>
                  </Container>
                ))
              }
            </Container>
          </Container>
        </Container>
      }
    </AnimatePresence>
  )
}