import { Container } from "@mui/material"
import { backgroundImage, containerCaseBanner1 } from "../../../../theme/styles"

export default function CaseBanner1({imageUrl}) {
  return (
    <Container 
      disableGutters 
      maxWidth={false} 
      sx={[containerCaseBanner1, {background: `url(${imageUrl})`}, backgroundImage]}/>
  )
}