import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../common/GridBG";
import ModuleHeader from "../common/ModuleHeader";
import { getImageUrl, mediaQueryBreakPoints } from "../../utils/functions";

import { containerGrid, headingSBluetec, headingMBlack, 
  aboutImages, mt40, alignItemsEnd } from "../../theme/styles";

export default function About({ data, width, height }) {
  const theme = useTheme()
  const selectImage = (images, i) => {
    const styles = {
      1: {...aboutImages},
      2: {...aboutImages, width: '200%'},
      3: {...aboutImages, objectPosition: 'center'},
      4: {...aboutImages, objectPosition: 'center'},
    };

    const aboutImage = images.data?.filter(item => item.attributes?.name === `about${i}.png`);

    return (
      aboutImage &&
      <Container disableGutters>
        <img 
          alt={aboutImage[0]?.attributes.url} 
          style={styles[i]} 
          src={getImageUrl(aboutImage[0]?.attributes.url)}
        />
      </Container>
    );
  };

  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'void'}/>
      {data && 
        <Grid container sx={containerGrid}>
          <Grid item l={5} s={6}>
            <ModuleHeader 
              title={data.title}
              titleClass={headingSBluetec}
              description={data.description}
              descriptionClass={headingMBlack}
            />
          </Grid>
          <Grid item l={1} s={0} />
          {width > mediaQueryBreakPoints.s &&
            <Grid item l={6} s={6} >
              <Grid container >
                <Grid item l={4} s={2}/>
                <Grid item l={4} s={6}>
                  {selectImage(data.images, 1)}
                </Grid>
                <Grid item l={4} s={4} sx={alignItemsEnd}>
                  <Grid item l={6} s={6}/>
                  <Grid item l={6} s={6}>
                    {selectImage(data.images, 2)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item l={8} s={6}>  
                  <Grid item l={9} s={8}>
                    {selectImage(data.images, 3)}
                  </Grid>
                </Grid>
                <Grid item l={4} s={4}>
                  <Grid item l={6} s={12}>
                    {selectImage(data.images, 4)}
                    {data.icons.data && <img 
                      style={mt40} 
                      alt={data.icons.data[0]?.attributes?.url} 
                      src={getImageUrl(data.icons.data[0]?.attributes?.url)}
                    />}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      }
    </>
  );
}
