import Component from '../components/common/Component';
import { useSeo } from '../hooks/hooks';

export default function Clients(props) {
  useSeo(props)
  return(
		<>
      <Component component='heroSection' from='clients'/>
      <Component component='caseList'/>
      <Component component='conversation'/>
      <Component component='footer'/>
		</>
	)
}