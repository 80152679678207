function SvgRect({rect, vpX, vpY, columns, rows, position}) {
  return (
    rect.positions[position] &&
    <rect
      x={rect.positions[position].x*vpX/rows}
      y={rect.positions[position].y*vpY/columns}
      height={rect.shapes[position].height*vpY/columns}
      width={rect.shapes[position].width*vpX/rows}
      stroke={rect.styles[position].stroke} 
      strokeWidth={1.5}
      fill={rect.styles[position].fill}
      style={{transition: 'all 1s'}}>
    </rect>
  )
}

function BigO({rect, vpX, vpY, columns, rows, position, bigO}) {
  return (
    rect.positions[position] &&
    <image 
      x={rect.positions[position].x*vpX/rows}
      y={rect.positions[position].y*vpY/columns}
      height={rect.shape.height*vpY/columns}
      width={rect.shape.width*vpX/rows}
      href={bigO}
      style={{transition: 'all 1s'}}
    />
  )
}

export {SvgRect, BigO}