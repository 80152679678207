import { Container } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import { BGGrid } from "../../theme/styles";
import { getGridCols } from "../../utils/functions";
import OverGrid from "./OverGrid";
import { patterns } from "./Patterns";
import { Fragment } from "react";

export default function GridBG({ width, height, color, pattern, patternImage }) {
  const cols = getGridCols(width);
  const svgString = encodeURIComponent(renderToStaticMarkup(<SVG width={width} height={height} color={color} columns={cols}/>))
  return (
    <>
      <Container disableGutters maxWidth={false} sx={[BGGrid, {background: `url('data:image/svg+xml;utf8, ${svgString}')`}]}/>
      <OverGrid width={width} height={height} pattern={patterns[pattern]} cols={cols} patternImage={patternImage}/>
    </>
  );
}

function SVG({ width, height, color, columns }) {
  const svgWidth = width * 0.75;
  const columnWidth = svgWidth / columns
  const svgHeight = height;
  const vBox = `0 0 ${svgWidth} ${svgHeight}`;

  let elements = [];
    for (let i = 0; i < columns; i++) {
      elements.push(
        i < (columns-1) ? 
        <path key={i} d={`M ${i*columnWidth+1} 0 L ${i*columnWidth+1} ${svgHeight} Z`} stroke={color} fillOpacity='0'/>
        :
        <Fragment key={i}>
          <path d={`M ${i*columnWidth} 0 L ${i*columnWidth} ${svgHeight} Z`} stroke={color} fillOpacity='0'/>
          <path d={`M ${i*columnWidth + columnWidth-1} 0 L ${i*columnWidth + columnWidth} ${svgHeight} Z`} stroke={color} fillOpacity='0'/>
        </Fragment>
      )
    }

  return (
    <svg width={'100%'} height={'100%'} viewBox={vBox} xmlns="http://www.w3.org/2000/svg">
      {elements}
    </svg>
  );
}
