import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import Carousel from "./Carousel";
import GridBG from "../common/GridBG";
import ModuleHeader from "../common/ModuleHeader";

import { containerGrid, headingSBluetec, headingLBlack, mb20 } from "../../theme/styles";

export default function Clients({ data, width, height }) {
  const theme = useTheme()
  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'void'} />
      <Grid container sx={containerGrid}>
          {data && 
            <>
              <Grid item l={12} sx={mb20}>
                <ModuleHeader 
                  title={data.title} 
                  titleClass={headingSBluetec}
                  subtitle={data.description} 
                  subtitleClass={headingLBlack}
                />
              </Grid>
              <Container disableGutters maxWidth={false} sx={{position: 'relative'}}>
                <Carousel array={data.clients?.data}/>
              </Container>
            </>
          }
      </Grid>
    </>
  );
}
