import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../common/GridBG";
import Carousel from "./Carousel";

import { containerGrid } from "../../theme/styles";

export default function Testimonials({data, width, height}) {
  const theme = useTheme()
  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'testimonials'}/>
        {data && 
          <Grid container sx={containerGrid}>
            <Carousel array={data.testimonials?.data}/>
          </Grid>
        }
    </>
  )
}