import Component from '../components/common/Component';
import { useSeo } from '../hooks/hooks';

export default function Home(props) {
  useSeo(props)
  return(
		<>
      <Component component='hero' />
      <Component component='verticals'/>
      <Component component='clients'/>
      <Component component='services'/>
      <Component component='technology'/>
      <Component component='about'/>
      <Component component='caseStudies'/>
      <Component component='testimonials'/>
      <Component component='conversation'/>
      <Component component='footer'/>
		</>
	)
}