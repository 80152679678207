import Component from '../components/common/Component';
import View from '../components/common/View';
import { useSeo } from '../hooks/hooks';

export default function AuxView(props) {
   useSeo(props)
  return(
		<>
      <View name={props.name} from='auxView'/>
      <Component component='footer'/>
		</>
	)
}