import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../common/GridBG";
import ModuleHeader from "../common/ModuleHeader";
import ButtonLink from "../common/ButtonLink";
import Carousel from "./Carousel";

import { headingSWhitec, headingLWhitec, containerGrid, bodyLeadWhitec } from "../../theme/styles";

export default function CaseStudies({ data, width, height }) {
  const theme = useTheme()
  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.white20} pattern={'caseStudies'}/>
      <Grid container sx={containerGrid}>
        {data && 
          <>
            <Grid item l={9}>
              <ModuleHeader 
                title={data.title}
                titleClass={headingSWhitec}
                subtitle={data.subtitle}
                subtitleClass={headingLWhitec}
                description={data.description}
                descriptionClass={bodyLeadWhitec}
              />
            </Grid>
            <Container disableGutters maxWidth={false} sx={{position: 'relative'}}>
              <Carousel array={data.cases?.data} />
            </Container>
            <Container disableGutters maxWidth={false}>
              <ButtonLink 
                buttonText={data.buttonText}
                buttonRoute={data.buttonRoute}
                withFilter={true}
              />
            </Container>
          </>
        }
      </Grid>
    </>
  );
}
