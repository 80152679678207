import Component from '../components/common/Component';
import { useSeo } from '../hooks/hooks';

export default function Us(props) {
   useSeo(props)
  return(
		<>
      <Component component='heroSection' from='us'/>
      <Component component='adn'/>
      <Component component='technology'/>
      <Component component='about'/>
      <Component component='team' />
      <Component component='conversation'/>
      <Component component='footer'/>
		</>
	)
}