import { Container, Typography, Avatar, Button } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";

import ModuleHeader from "../common/ModuleHeader";
import rightChevron from '../../assets/svg/rightChevronWhite.svg';

import { usePopupVisibility } from "../../contexts/Contexts";
import { popUpAnimation } from "../../utils/animations";

import { flexColumn, headingMBlack, headingSBluetec, alignItemsCenter, 
  bodyTextSGrey, servicesAvatarArrow, serviceDropdown, mb20, 
  bodyLeadBluetec, formContactContainer, formCloseButton, formCloseButtonText } from "../../theme/styles";
import { useTheme } from "@emotion/react";

export default function ContactPopUp({isVisible, data}) {
  const theme = useTheme()
  const context = usePopupVisibility()

  const wrapperRef = useRef(null);

  const toggleAppPopUp = (f) => {
    context.toggleContactPopup()
    context.toggleAppPopup(f)
  }
  
  return (
    <>
    <AnimatePresence>
      {isVisible && data &&
      <Container
        disableGutters 
        ref={wrapperRef}
        component={motion.div}
        initial={popUpAnimation.hidden}
        animate={popUpAnimation.visible}
        exit={popUpAnimation.hidden}
        transition={popUpAnimation.transition}
        sx={formContactContainer}>
        <Button 
          onClick={() => {context.toggleContactPopup()}} 
          sx={[formCloseButton, {background: theme.palette.bluetec40, '&:hover': {background: theme.palette.bluetec}}]}>
          <Typography sx={formCloseButtonText}>{'X'}</Typography>
        </Button>
        <ModuleHeader
          title={data.title}
          titleClass={headingSBluetec}
          description={data.description}
          descriptionClass={headingMBlack}
          />
        <Container disableGutters sx={flexColumn}>
          {
            data.forms?.map((f, i) => {
              return (f &&
                <Container key={i} disableGutters 
                  onClick={()=>{toggleAppPopUp(f)}} 
                  sx={[serviceDropdown, mb20]}>
                  <Container disableGutters sx={alignItemsCenter}>
                    <Container disableGutters sx={flexColumn}>
                      <Typography sx={bodyLeadBluetec}>{f.title}</Typography>
                      <Typography sx={bodyTextSGrey}>{f.subtitle}</Typography>
                    </Container>
                    <Avatar sx={servicesAvatarArrow}>
                      <img alt={''} width={'70%'} src={rightChevron}/>
                    </Avatar>
                  </Container>
                </Container>
              )
            })
          }
        </Container>
      </Container>}
    </AnimatePresence>
    </>
  )
}