// Se compone de tantos botones como campos de Expertise existan en la BD
// Cada botón controla el contenido del container de texto
import { useEffect, useState } from "react";
import { Grid, Typography, Container } from "@mui/material";
import { motion } from "framer-motion";

import { getImageUrl, mediaQueryBreakPoints } from "../../utils/functions";

import Buttons from "../common/Buttons";
import ButtonLink from "../common/ButtonLink";

import { bodyLWhitec300, bodyTextWhitec, cardVerticals, 
  buttonVerticalTextWhite, mb20, mb10, mb40, flexRow, flexWrap, headingSWhitec, verticalImage, alignItemsCenter } from "../../theme/styles";
import { expertiseAndTechnologyAnimation } from "../../utils/animations";
import { useDragScroll } from "../../hooks/hooks";

export default function Expertise({width, btnOptions, buttonText, buttonRoute}) {
  const [options, setOptions] = useState([])
  const [buttonStates, setButtonStates] = useState([true, false, false, false])
  const [boxIcon, setBoxIcon] = useState(null)
  const [boxText, setBoxText] = useState(null)
  const [boxTitle, setBoxTitle] = useState(null)
  const [boxName, setBoxName] = useState(null)
  const [buttonContainer, setButtonContainer] = useState({})
  const { containerRef, isDragging } = useDragScroll();

  useEffect (() => {
    setOptions(btnOptions)
    setButtonStates(btnOptions.map((_, index) => {return (index === 0)}))
    setBoxIcon(btnOptions[0]?.attributes?.icon?.data?.attributes?.url)
    setBoxText(btnOptions[0]?.attributes?.description)
    setBoxTitle(btnOptions[0]?.attributes?.title)
    setBoxName(btnOptions[0]?.attributes?.name)
  }, [btnOptions])

  useEffect (() => {
    if (width < mediaQueryBreakPoints.l) {
      setButtonContainer([flexRow, {
        overflowY: 'auto', 
        overflowX: 'auto', 
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        scrollBehavior: 'unset',
        '&::-webkit-scrollbar': {
          width: '0px', 
          background: 'transparent'
        }}])
    } else {
      setButtonContainer({})
    }
  }, [width])
  
  const manageLines = (i) => {
    setButtonStates(options.map((_, index) => {return (index === i)}))
    setBoxIcon(options[i]?.attributes?.icon?.data?.attributes?.url)
    setBoxText(options[i]?.attributes?.description)
    setBoxTitle(options[i]?.attributes?.title)
    setBoxName(options[i]?.attributes?.name)
  }

  return (
    <Grid container columns={7}>
      {width > mediaQueryBreakPoints.xs ? 
      <>
        <Grid ref={containerRef} item l={3} s={12} xs={12} sx={buttonContainer}>
          {options.map((data, index) => {
            return (
              data &&
              <Buttons 
                key={index}
                onClick={!isDragging && manageLines}
                title={data.attributes?.name} 
                index={index} 
                width={width} 
                state={buttonStates[index]} 
                textClass={buttonVerticalTextWhite}/>
            )
          })}
        </Grid>
        <Grid item l={4} style={{width: '100%'}}>
          <Container 
            sx={[cardVerticals, {minHeight: containerRef.current?.clientHeight}]}
            component={motion.div}
            layout
            transition={expertiseAndTechnologyAnimation}
            >
            <img alt={boxIcon} style={mediaQueryBreakPoints.xl<width ? mb20 : mb10} src={getImageUrl(boxIcon)} />
            <Typography sx={[bodyLWhitec300, mediaQueryBreakPoints.xl<width ? mb20 : mb10]}>{boxTitle}</Typography>
            <Typography sx={[bodyTextWhitec, mediaQueryBreakPoints.xl<width ? mb20 : mb10]}>{boxText}</Typography>
            <ButtonLink 
              buttonText={buttonText}
              buttonRoute={buttonRoute}
              withFilter={true}
              name={boxName}
              />
          </Container>
        </Grid>
      </>
       :
        <Grid item l={4}  sx={{width: '100%'}}>
          {options.map((data, index) => {
            return (
              data && data.attributes &&
              <Container key={index} sx={[cardVerticals, mb40]}>
                <Container disableGutters sx={[flexRow, flexWrap, alignItemsCenter]}>
                  <img alt={boxIcon} style={verticalImage} src={getImageUrl(data.attributes.icon?.data?.attributes?.url)} />
                  <Typography sx={[headingSWhitec, mb20]}>{data.attributes.name}</Typography>
                </Container>
                <Typography sx={[bodyLWhitec300, mb20]}>{data.attributes.title}</Typography>
                <Typography sx={[bodyTextWhitec, mb20]}>{data.attributes.description}</Typography>
                <ButtonLink 
                  buttonText={buttonText}
                  buttonRoute={buttonRoute}
                  withFilter={true}
                  name={data.attributes.name}
                  />
              </Container>
            )
          })}
        </Grid>
      }
    </Grid>
  )
}
