import Component from '../components/common/Component';
import { useSeo } from '../hooks/hooks';

export default function Experience(props) {
   useSeo(props)
  return(
		<>
      <Component component='heroSection' from='experience'/>
      <Component component='services'/>
      <Component component='verticals'/>
      <Component component='testimonials'/> 
      <Component component='caseStudies'/>
      <Component component='footer'/>  
		</>
	)
}