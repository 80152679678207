import Component from '../components/common/Component';
import { useSeo } from '../hooks/hooks';

export default function Contact(props) {
  useSeo(props)
  return(
		<>
    <Component component='heroSection' from='contact'/>
    <Component component='offices' />
    <Component component='footer'/>
    </>
	)
}