import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ReCaptchaComponent = React.forwardRef((props, ref) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  React.useImperativeHandle(ref, () => ({
    async execute() {
      if (!executeRecaptcha) {
        return '';
      }
      return await executeRecaptcha('formSubmit');
    },
  }));

  return null;
});

export default ReCaptchaComponent;