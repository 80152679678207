import { usePopupVisibility } from "../../contexts/Contexts";
import ContactPopUp from "./ContactPopUp";
import FormPopUp from "./FormPopUp";

export default function PopUpManager({ data }) {
  const {
    contactPopupVisible,
    appPopupVisible,
    formData
  } = usePopupVisibility();

  return (
    <>
      {data && <ContactPopUp isVisible={contactPopupVisible} data={data} />}
      {formData && <FormPopUp isVisible={appPopupVisible} recaptchaKey={data.menu?.reCaptchaPublic} data={formData} />}
    </>
  );
}