import { Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

import GridBG from "../common/GridBG";
import Links from "./Links";
import { configureRoute, getGridCols, getImageUrl, mediaQueryBreakPoints } from "../../utils/functions";
import { useLanguageData } from "../../contexts/Contexts";

import { alignItemsCenter, bodyTextXSBlackBold, bodyTextXSBluetec, bodyTextXSBluetecBold, displayFlex, 
  flexRow, footerAuxLink, footerAuxLinksContainer, footerBottomWrapper, footerContainerGrid, footerImage, 
  footerLink, footerLocaleContainer, footerLocationsContainer, footerLocationsWrapper, 
  footerLogoSocialContainer, footerRoutesContainer, footerSocialsContainer, footerSocialsIcon, footerUEContainer, 
  menuButtonText, menuLink, upperCase } from "../../theme/styles";

export default function Footer({ data, width, height }) {
  const theme = useTheme()
  const languageContext = useLanguageData()

  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'footer'}/>
      {data && 
        <Grid container columns={getGridCols(width)} sx={footerContainerGrid}>
          <Container disableGutters maxWidth={false} sx={[displayFlex, {flexWrap: 'wrap'}]}>
            <Grid item l={2} none={width < mediaQueryBreakPoints.xs ? 12 : 2}>
              <Container disableGutters sx={footerLogoSocialContainer}>
                <img 
                  alt={data.logo?.data?.attributes?.name} 
                  src={getImageUrl(data.logo?.data?.attributes?.url)}
                />
                <Container disableGutters sx={footerSocialsContainer}>
                  { data.socials?.map((s, i) => {
                    return (
                      <a key={i} style={footerLink} target={'_blank'} rel="noreferrer" href={s.url}>
                        <img 
                          style={footerSocialsIcon} 
                          alt={s.name} 
                          src={getImageUrl(s.icon?.data?.attributes?.url)} 
                        />
                      </a>
                    )    
                  })}
                </Container>
                <Container disableGutters maxWidth={false} sx={footerLocaleContainer}>
                  {languageContext.locales && languageContext.languageSelected &&
                    languageContext.locales?.map((l, i) => (
                      <Container disableGutters key={i} onClick={() => {languageContext.handleSetLocale(l.code)}} sx={[flexRow, alignItemsCenter, menuLink, {zIndex: 4, cursor: 'pointer', padding: 'auto'}]}>
                        <Typography sx={[menuButtonText, {color: theme.palette.bluetec}]}>
                          <span style={l.code === languageContext.languageSelected ? {fontWeight: 700} : {}}>
                            {l.code}
                          </span>
                        </Typography>
                      </Container>
                    ))
                  }
                </Container>
              </Container>
            </Grid>
            <Grid item l={1} none={width < mediaQueryBreakPoints.xs ? 0 : 1}/>
            <Grid item none={1}>
              <Container disableGutters sx={footerRoutesContainer}>
                {languageContext.languageSelected &&
                data.routes?.data.map((route, i) => {
                return (
                  <div key={i} style={{zIndex: 4}}>
                    <Links 
                      to={configureRoute(route.attributes?.route, languageContext.languageSelected)} 
                      text={route.attributes?.name}
                      textClass={bodyTextXSBluetecBold} 
                    />
                  </div>
                  )
                })}
              </Container>
            </Grid>
            <Grid item l={1} xxs={1} none={1}/>
            <Grid item l={6} xs={2} none={2}>
              <Container disableGutters sx={footerLocationsWrapper}>
                { data.locations?.data?.map((location) => {
                  return (
                    <Container disableGutters key={location.attributes?.city} sx={footerLocationsContainer}>
                      <Typography sx={[bodyTextXSBluetecBold, upperCase, {width: 'unset'}]} key={location.attributes?.city}>
                        {location.attributes?.city}
                      </Typography>
                      <Typography sx={[bodyTextXSBluetec, {width: 'unset'}]} key={location.attributes?.address}>
                        {location.attributes?.address}
                      </Typography>
                      <Typography sx={[bodyTextXSBluetec]} key={location.attributes?.phone}>
                        T: {location.attributes?.phone}
                      </Typography>
                    </Container>
                  )
                })}
              </Container>
            </Grid>
            <Grid item l={0} s={1}/>
            <Grid item l={12} xs={width > mediaQueryBreakPoints.m ? 4 : 12}>
              <Container disableGutters maxWidth={false} sx={footerBottomWrapper}>
                <Grid item l={4} s={12}>
                  <Container disableGutters sx={footerUEContainer}>
                    <img 
                      style={footerImage} 
                      alt={data.UEImage?.data?.attributes?.name} 
                      src={getImageUrl(data.UEImage?.data?.attributes?.url)}
                    />
                    <Typography sx={bodyTextXSBlackBold}>{data.UEDescription}</Typography>
                  </Container>
                </Grid> 
                <Grid item l={1} s={1}/> 
                <Grid item l={7} s={12}>
                  <Container disableGutters sx={footerAuxLinksContainer}>
                    {languageContext.languageSelected &&
                    data.auxRoutes?.data?.map((link, i) => {
                      return (
                        <Link style={footerAuxLink} key={i} to={configureRoute(link.attributes?.route, languageContext.languageSelected)}>
                          <Typography sx={bodyTextXSBluetec}>{link.attributes?.name}</Typography>
                        </Link>
                      )
                    })}
                  </Container>
                </Grid> 
              </Container>
            </Grid>            
          </Container>
        </Grid>
      }
    </>
  );
}
