import theme from "../../theme/theme"

export const patterns = {
  verticals: {
    xxxl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 5 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 4 },
        shape: { width: 3, height: 2 }
      },
      {
        type: 'bigO',
        position: {x: 9.75, y: 4.25 },
        shape: { width: 1.5, height: 1.5 }
      }
    ],
    xxl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 5 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 4 },
        shape: { width: 3, height: 2 }
      },
      {
        type: 'bigO',
        position: {x: 9.75, y: 4.25 },
        shape: { width: 1.5, height: 1.5 }
      }
    ],
    xl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 5 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 4 },
        shape: { width: 3, height: 2 }
      },
      {
        type: 'bigO',
        position: {x: 9.75, y: 4.25 },
        shape: { width: 1.5, height: 1.5 }
      }
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 5 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    m: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 0 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 1 },
        shape: { width: 2, height: 1 }
      },
    ],
    s: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 0 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 1 },
        shape: { width: 1, height: 1 }
      },
    ],
    xs: [],
    xxs: [],
    xxxs: [],
    none: []
  },
  footer: {
    xxxl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 3 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: 3 }
      },
    ],
    xxl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 3 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: 3 }
      },
    ],
    xl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 3 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: 3 }
      },
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 3 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: 3 }
      },
    ],
    m: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 5 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 4 },
        shape: { width: 1, height: 2 }
      },
    ],
    s: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 0 },
        shape: { width: 1, height: 2 }
      },
    ],
    xs: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 0 },
        shape: { width: 1, height: 2 }
      },
    ],
    xxs: [],
    xxxs: [],
    none: []
  },
  adn: {
    xxxl:[
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 3.7 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 2 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    xxl:[
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 3.7 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 2 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    xl:[
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 3.7 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 2 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 3.7 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 2 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    m: [
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 3.7 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 2 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    s: [
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 3.7 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 4, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 2 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    xs: [
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 3.7 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 4, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 2 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    xxs: [
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 1 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 4 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 1 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 5, y: 4 },
        shape: { width: 1, height: 1 }
      },
    ],
    xxxs: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 3 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 2 },
        shape: { width: 1, height: 1.5 }
      },
    ],
    none: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 3 },
        shape: { width: 1, height: 1.5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 4 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 2 },
        shape: { width: 1, height: 1.5 }
      },
    ]
  },
  services: {
    xxxl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 5 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    xxl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 5 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    xl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 5 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 9, y: 5 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    m: [],
    s: [],
    xs: [],
    xxs: [],
    xxxs: [],
    none: []
  },
  technology: {
    xxxl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 5 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    xxl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 5 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    xl: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 5 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 3, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 2, y: 5 },
        shape: { width: 2, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 4 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 5 },
        shape: { width: 1, height: 1 }
      }
    ],
    m: [],
    s: [],
    xs: [],
    xxs: [],
    xxxs: [],
    none: []
  },
  team: {
    xxxl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 5.5 },
        shape: { width: 2, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 5 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    xxl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 5.5 },
        shape: { width: 2, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 5 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    xl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 5.5 },
        shape: { width: 2, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 5 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 5.5 },
        shape: { width: 2, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: 5 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 5.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    m: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 8, y: 0 },
        shape: { width: 2, height: .3 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 10, y: .3 },
        shape: { width: 1, height: .3 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: .3 }
      }
    ],
    s: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 0 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 0 },
        shape: { width: 1, height: .3 }
      },
    ],
    xs: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 6, y: 0 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.bluetec,
        stroke: theme.palette.bluetec,
        position: {x: 7, y: 0 },
        shape: { width: 1, height: .3 }
      },
    ],
    xxs: [],
    xxxs: [],
    none: []
  },
  testimonials: {
    xxxl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 1.5 },
        shape: { width: 1, height: 1 }
      },
    ],
    xxl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 1.5 },
        shape: { width: 1, height: 1 }
      },
    ],
    xl:[
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 1.5 },
        shape: { width: 1, height: 1 }
      },
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.bluetec20,
        stroke: theme.palette.bluetec,
        position: {x: 0, y: 0 },
        shape: { width: 1, height: 2 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.bluetec,
        position: {x: 1, y: 1.5 },
        shape: { width: 1, height: 1 }
      },
    ],
    m: [],
    s: [],
    xs: [],
    xxs: [],
    xxxs: [],
    none: []
  },
  caseStudies: {
    xxxl: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 10, y: 0 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.white,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.white,
        stroke: theme.palette.white,
        position: {x: 11, y: 1.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    xxl: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 10, y: 0 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.white,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.white,
        stroke: theme.palette.white,
        position: {x: 11, y: 1.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    xl: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 10, y: 0 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.white,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.white,
        stroke: theme.palette.white,
        position: {x: 11, y: 1.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    l: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 10, y: 0 },
        shape: { width: 1, height: 1 }
      },
      {
        type: 'rect',
        fill: theme.palette.transparent,
        stroke: theme.palette.white,
        position: {x: 11, y: 0 },
        shape: { width: 1, height: .5 }
      },
      {
        type: 'rect',
        fill: theme.palette.white,
        stroke: theme.palette.white,
        position: {x: 11, y: 1.5 },
        shape: { width: 1, height: .5 }
      }
    ],
    m: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 0, y: 5.5 },
        shape: { width: 2, height: .5 }
      },
    ],
    s: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 0, y: 5.5 },
        shape: { width: 2, height: .5 }
      },
    ],
    xs: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 0, y: 5.5 },
        shape: { width: 2, height: 1 }
      },
    ],
    xxs: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 0, y: 5.5 },
        shape: { width: 2, height: 1 }
      },
    ],
    xxxs: [
      {
        type: 'rect',
        fill: theme.palette.whitec40,
        stroke: theme.palette.white,
        position: {x: 3, y: 0 },
        shape: { width: 1, height: .5 }
      },
    ],
    none: []
  },
  void: {
    xxxl: [],
    xxl: [],
    xl: [],
    l: [],
    m: [],
    s: [],
    xs: [],
    xxs: [],
    xxxs: [],
    none: []
  },
}