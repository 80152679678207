import { Container, Grid } from "@mui/material"

import { bodyLeadBlack, containerGrid, flexRow, flexWrap, 
  headingLBlack, headingSBluetec, relatedContainer } from "../../../../theme/styles"
import ModuleHeader from "../../../common/ModuleHeader"


export default function CaseRelated({caseType, related}) {
  return (
    <Container disableGutters maxWidth={false} sx={relatedContainer}>
      <Grid container sx={[containerGrid]}>
        <ModuleHeader
          title={`${related?.title}`}
          titleClass={headingSBluetec}
          />
        <Container disableGutters maxWidth={false} sx={[flexRow, flexWrap, {gap: 10}]}>
          {related?.cases?.data?.map((c, i) => {
            return (
              c && c.attributes &&
              <Grid item s={5} key={i}>
                <ModuleHeader
                  title={c.attributes.client?.data?.attributes?.name}
                  titleClass={headingLBlack}
                  description={c.attributes.client?.data?.attributes?.description}
                  descriptionClass={bodyLeadBlack}
                  buttonText={c.attributes.buttonText}
                  buttonRoute={`/${c.attributes.case_type?.data?.attributes?.name?.toLowerCase().replace(/\s/g, '')}/${c.attributes.identifier}`}
                  />
              </Grid>
            )
          })}
        </Container>
      </Grid>
    </Container>
  )
}