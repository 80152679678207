const emailTemplate = (data) => `
<!DOCTYPE html>
<html>
<head>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
    }
    .container {
      width: 80%;
      margin: 0 auto;
      background-color: #F0F0F0;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .header {
      background-color: #005bff;
      color: white;
      padding: 10px 0;
      text-align: center;
      border-radius: 10px 10px 0 0;
    }
    .content {
      padding: 20px;
    }
    .content p {
      margin: 10px 0;
    }
    .footer {
      text-align: center;
      font-size: 12px;
      color: #777;
      margin-top: 20px;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">
      <h1>${data.subject}</h1>
    </div>
    <div class="content">
      <p><strong>Email:</strong> ${data.email}</p>
      <p><strong>Name:</strong> ${data.name}</p>
      <p><strong>Company:</strong> ${data.company}</p>
      <p><strong>Type:</strong> ${data.companyType}</p>
      <p><strong>Phone:</strong> ${data.phone}</p>
      <p><strong>Message:</strong></p>
      <p>${data.text}</p>
    </div>
    <div class="footer">
      <p>&copy; ${new Date().getFullYear()} Brooktec.</p>
    </div>
  </div>
</body>
</html>
`;

export default emailTemplate;