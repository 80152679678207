import { Grid, Typography, Container } from "@mui/material"
import { motion } from "framer-motion" 
import { Link } from "react-router-dom"
import { useTheme } from "@emotion/react"

import CaseChip from "../common/CaseChip"

import { configureRoute, getImageUrl, mediaQueryBreakPoints } from "../../utils/functions"
import { selectorAnimation } from "../../utils/animations"

import { caseListItemContainer, caseListItemImage, bodyLBlack, 
  bodyTextBlack, mb20, caseItemButton, alignItemsCenter, flexSpaceBetween, 
  flexRow, headingSBluetec, caseListItemWrapper, mtAuto, caseListItemLink } from "../../theme/styles"
import { useLanguageData } from "../../contexts/Contexts"

export default function CaseItem({item, width}) {
  const theme = useTheme()
  const { languageSelected } = useLanguageData()

  return (
      <Grid 
        component={motion.div} 
        sx={[caseListItemWrapper, width < mediaQueryBreakPoints.l ? width < mediaQueryBreakPoints.s ? {width: '400px'} : {width: '300px'} : {width: '400px'}]}
        layout
        initial={selectorAnimation.initial}
        animate={selectorAnimation.animate}
        exit={selectorAnimation.exit}
        item lg={1}>
        {item && item.attributes &&
        <Container maxWidth={false} sx={caseListItemContainer}>
          <Link to={configureRoute(`/${item.attributes.case_type?.data?.attributes.name.toLowerCase().replace(/\s/g, '')}/${item.attributes.identifier}`, languageSelected)} style={caseListItemLink}>
            <Container id={'pic'} disableGutters sx={[caseListItemImage, {backgroundImage: `url(${getImageUrl(item.attributes.thumbnail?.data?.attributes.url)})`}]}/>
            <Typography sx={[bodyLBlack, mb20]}>{item.attributes.client?.data?.attributes.name}</Typography>
            <Typography sx={[bodyTextBlack, mb20]}>{item.attributes.client?.data?.attributes.description}</Typography>
            <Container disableGutters maxWidth={false} sx={[flexRow, flexSpaceBetween, alignItemsCenter, mtAuto]}>
              <CaseChip 
                theme={theme} 
                caseType={item.attributes.case_type?.data?.attributes.name} 
                isBig={false} 
                label={item.attributes.case_type?.data?.attributes.shortName}/>
              <Container disableGutters maxWidth={false} 
                id={'link-button'} 
                sx={caseItemButton} >
                <Typography sx={[headingSBluetec, {margin: 'auto'}]}>{"\u2192"}</Typography>
              </Container>
            </Container>
          </Link>
        </Container>}
      </Grid>
    
  )
}