import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { Container } from "@mui/material";

import CaseIntroduction from "./components/introduction/CaseIntroduction";
import CaseBanner1 from "./components/banner1/CaseBanner1";
import CaseRelated from "./components/related/CaseRelated";
import Menu from "../menu/Menu";

import { getImageUrl } from "../../utils/functions";

import { containerFitWhitec, containerMenuWhitec, flexColumn } from "../../theme/styles";
import { useHelmetData } from "../../contexts/Contexts";

export default function LinkView({data}) {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const elementRef = useRef(null);
  const context = useHelmetData()

  const useWindowSize  = () => {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([elementRef.current?.offsetWidth, elementRef.current?.offsetHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize(elementRef)
  useEffect(() => {
    setSize([elementRef.current?.offsetWidth, elementRef.current?.offsetHeight]);
    if (data.seo) {
      context.setSeoTitle(data.seo?.metaTitle)
      context.setSeoDescription(data.seo?.metaDescription)
      context.setKeywords(data.seo?.keywords)
      context.setRobots(data.seo?.metaRobots)
      context.setRobots(data.seo?.metaViewport)
      context.setCanonicalUrl(data.seo?.canonicalURL)
      context.setMetaImage(getImageUrl(data.seo?.metaImage?.data?.attributes?.url))
    } else {
      context.setSeoTitle(`${data.case_type?.data?.attributes?.name} - ${data.title}`)
    }
  }, [data, context])
  
  return (
    data && width && height &&
    <Container ref={elementRef} disableGutters maxWidth={false} sx={[containerFitWhitec, flexColumn]} >
      <Container maxWidth={false} sx={containerMenuWhitec}>
        <Menu 
          width={width} 
          data={data.menu} 
          option={'caseView'} 
          />
      </Container>
      <CaseIntroduction 
        data={data.client?.data?.attributes} 
        expertise={data.expertise?.data?.attributes} 
        from ={'Link'}
        routes={[]}
        width={width}
        height={height}/>
      {data.banner1?.data &&
      <CaseBanner1 
        imageUrl={getImageUrl(data.banner1?.data?.attributes?.url)}
        />}
      {data.related?.cases?.data?.length > 0 && 
      <CaseRelated
        caseType={data.expertise?.data?.attributes?.name}
        related={data.related}
        /> } 
    </Container>
  )
}