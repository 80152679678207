import { Container, Grid, Typography } from "@mui/material"

import { caseSolutionText, solutionContainer } from "../../../../theme/styles"

export default function Solution({solution, isBig}) {
  return (
    <>
    <Grid item l={isBig ? 3.85 : 2.5} s={isBig ? 4.3 : 2.8} xxs={10} style={{width: '100%'}}>
      <Container disableGutters sx={solutionContainer}> 
        <Typography sx={caseSolutionText}>{solution?.title}</Typography>
      </Container>
    </Grid>
    <Grid item xxs={.2}/>
    </>
  )
}