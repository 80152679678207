import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../common/GridBG";
import ModuleHeader from "../common/ModuleHeader";

import { containerGrid, headingSBluetec, headingLBlack, adnContainer } from "../../theme/styles";

export default function Adn({ data, width, height }) {
  const theme = useTheme()
  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'adn'} />
      <Grid container sx={containerGrid}>
        {data && 
          <Container maxWidth={false} disableGutters sx={adnContainer}>
            <ModuleHeader 
              title={data.title}
              titleClass={headingSBluetec}
              subtitle={data.subtitle}
              subtitleClass={headingLBlack}
            />
          </Container>
        }
      </Grid>
    </>
  );
}