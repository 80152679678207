import { useTheme } from "@emotion/react";
import { bodyTextSWhitec, footerAuxLink } from "../../theme/styles";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { configureRoute } from "../../utils/functions";
import { useEffect, useState } from "react";
import { useLanguageData, usePopupVisibility } from "../../contexts/Contexts";
import { useStrapiLocale } from "../../hooks/hooks";

export default function CheckBoxForm({isChecked, handleCheckboxChange, checkBoxText}) {
  const theme = useTheme()
  const context = usePopupVisibility()
  const [privacyRoute, setPrivacyRoute] = useState()
  const localeContext = useLanguageData()
  const strapiRoute = useStrapiLocale(`routes?filters[identifier][$eq]=privacy-policy&populate=*&locale=${localeContext.languageSelected}`)

  useEffect(()=>{
    if (strapiRoute) {
      if (strapiRoute.data?.length>0) {
        setPrivacyRoute(strapiRoute.data[0])
      }
    }
  }, [strapiRoute])

  const styles = {
    customCheckbox: {
      display: 'flex',
      position: 'relative',
      cursor: 'pointer',
    },
    input: {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: 0,
      width: 0,
    },
    checkmark: {
      height: '20px',
      width: '20px',
      backgroundColor: theme.palette.whitec,
      borderRadius: '3px',
    },
    checkmarkAfter: {
      content: '""',
      position: 'absolute',
      display: 'none',
      left: '6px',
      top: '2px',
      width: '5px',
      height: '10px',
      border: 'solid',
      borderColor: theme.palette.bluetec,
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)',
    },
    checkmarkAfterChecked: {
      display: 'block',
    },
    labelText: {
      ...bodyTextSWhitec,
      marginLeft: '10px',
      letterSpacing: 1,
      alignSelf: 'center'
    },
  };

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
    <label style={styles.customCheckbox}>
      <input
        type="checkbox"
        style={styles.input}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span
        style={{
          ...styles.checkmark,
        }}
      >
        <span
          style={{
            ...styles.checkmarkAfter,
            ...(isChecked ? styles.checkmarkAfterChecked : {}),
          }}
        ></span>
      </span>
      <span style={styles.labelText}>{checkBoxText}</span>
    </label>
    <Link style={footerAuxLink} to={configureRoute(privacyRoute?.attributes?.route, localeContext.languageSelected)} onClick={()=>context.toggleAppPopup()}>
      <Typography sx={{...styles.labelText, color: theme.palette.white, fontWeight: 700}}>{privacyRoute?.attributes?.name.toLowerCase()}</Typography>
    </Link>
    </div>
  );
}