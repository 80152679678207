import { useState, useRef, useEffect } from "react"
import { Container, Grid, Button, Typography } from "@mui/material"
import { useTheme } from "@emotion/react"

import GridBG from "../common/GridBG"
import ModuleHeader from "../common/ModuleHeader"
import Location from "./Location"
import rightArrow from "../../assets/svg/rightArrow.svg"

import { usePopupVisibility } from "../../contexts/Contexts"

import { containerGrid, containerOffice, headingLBlack, 
  headingSBluetec, locationsWrapper, mainButton, mainButtonText, mb40 } from "../../theme/styles"

export default function Offices({ data, width }) {
  const [finalHeight, setHeight] = useState(1024);
  const elementRef = useRef(null);
  const theme = useTheme()
  const context = usePopupVisibility()

  useEffect(() => {
    setHeight(elementRef.current.offsetHeight);
  }, [])

  const handleContactClick = () => {

    context.toggleContactPopup()
  }

  return (
    <>
      <GridBG width={width} height={finalHeight} color={theme.palette.bluetec20} pattern={'void'} />
      <Container ref={elementRef} disableGutters maxWidth={false} sx={containerOffice}>
        <Grid container columns={12} sx={containerGrid}>
          {data && 
            <div style={{width: '100%'}}>
              <Grid item l={12}>
                <ModuleHeader 
                  title={data.title} 
                  titleClass={headingSBluetec}
                  subtitle={data.subtitle} 
                  subtitleClass={headingLBlack} 
                />
              </Grid>
              <Container disableGutters maxWidth={false} sx={locationsWrapper}> 
                {data.locations?.data?.map((l, i) => (
                  <Grid key={i} item l={6} md={12} sx={mb40}>
                    <Location location={l} width={width}/>
                  </Grid>
                ))}
                <Grid item l={6} sx={mb40}>
                <Button sx={mainButton} variant="contained" onClick={handleContactClick}>
                  <Typography sx={mainButtonText}>{data.buttonText}</Typography>
                  &nbsp;&nbsp;&nbsp;
                  <img alt={''} src={rightArrow} />
                </Button>
                </Grid>
              </Container>
            </div>
          }
        </Grid>
      </Container>
    </>
  )
}
