import { Container, Box, Typography } from "@mui/material";

import { getImageUrl } from "../../utils/functions";

import { 
  bodyLeadBlack, bodyTextBlack, buttonLinkTextBluetec, 
  testimonialCarouselAvatar, testimonialCarouselImage, 
  testimonialCarouselItem } from "../../theme/styles";

export default function Testimonial({imageUrl, name, job, testimony}) {
  return (
    <Box sx={testimonialCarouselItem}>
      <Container disableGutters style={testimonialCarouselAvatar}>
        <Container disableGutters sx={[testimonialCarouselImage, {backgroundImage: `url(${getImageUrl(imageUrl)})`}]}/>
        <Typography sx={bodyLeadBlack}>{name}</Typography>
        <Typography sx={buttonLinkTextBluetec}>{job}</Typography>
      </Container>
      <Typography sx={bodyTextBlack}>{testimony}</Typography>
    </Box>
  )
}