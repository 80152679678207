import AuxView from '../components/auxView/AuxView';
import CaseView from '../components/viewCase/CaseView';
import LinkView from "../components/viewCase/LinkView";

import { containerFitWhitec, containerLBlacktec, containerLBluetec, 
  containerLHero, containerLWhitec, containerMSWhitec, containerMWhitec, 
  containerSWhitec, containerXLWhitec, containerXSWhitec, conversationOutterContainer,
  containerMethodology, containerRelation, containerLHeroSection } from "../theme/styles"

const getGridCols = (width) => {
  return width > 1024 ? 12 : width > 768 ? 8 : width > 481 ? 6 : 4
}

const getStrapiEndpoints = (component) => {
  const uris = {
    locale: "i18n/locales",
    routes: "routes?populate[0]=seo.metaImage&locale=all&pagination[limit]=200",
    redirects: 'redirects?pagination[limit]=200',
    hero: ["component-hero?populate[0]=mainContent.background&locale=all", "component-menu?populate[0]=routes,logoBlue1,logoBlue2,logoWhite1,logoWhite2,radioDefaultBlue,radioDefaultWhite,radioHoverBlue,radioHoverWhite&locale=all"],
    heroSection: ["component-hero?populate[0]=sectionContent.background&locale=all", "component-menu?populate[0]=routes,logoBlue1,logoBlue2,logoWhite1,logoWhite2,radioDefaultBlue,radioDefaultWhite,radioHoverBlue,radioHoverWhite&locale=all"],
    verticals: ["component-vertical?populate[0]=buttonLeftIcon,buttonRightIcon,bigO&populate[1]=expertise.icon&locale=all"],
    clients: ["component-client?populate[0]=clients.logo&locale=all"],
    conversation: ["component-conversation?populate=*&locale=all"],
    footer: ["component-footer?populate[0]=UEImage,logo,locations.image,routes,auxRoutes,socials.icon&locale=all"],
    about: ["component-about?populate=*&locale=all"],
    adn: ["component-adn?populate=*&locale=all"],
    services: ["component-service?populate=*&locale=all"],
    technology: ["component-technology?populate[technologies][populate]=technologies,icon&locale=all"],
    methodology: ["component-methodology?populate=*&locale=all"],
    offices: ['component-office?populate[0]=locations.image&populate[1]=buttonIcon&locale=all'],
    team: ["component-team?locale=all&populate[workers][populate]=*&populate=*"],
    relation: ["component-relation?locale=all&populate=*"],
    testimonials: ["component-testimonial?populate[0]=testimonials.image&locale=all"],
    form: ["component-form-contact?populate[0]=forms.form_option_entries.expertise&locale=all", "general-configuration?locale=all"],
    caseStudies: ["component-case?populate[0]=cases.client,cases.thumbnail,buttonIcon,cases.case_type&locale=all"],
    caseList: ["component-project?locale=all&populate[0]=cases.client,cases.thumbnail,expertise,cases.case_type,cases.expertise"],
    caseView: ["cases?filters[identifier][$eq]=###identifier###&populate[0]=thumbnail,banner1,banners2,goals.goals,challenge.image,solution.solutions,anchors,results.image,improvements,related.cases.client,related.cases.case_type,case_type,technology.technologies,expertise,testimonials.image,client.testimonials.image,seo,seo.metaImage&locale=all",
    "component-menu?populate[0]=routes,logoBlue1,logoBlue2,logoWhite1,logoWhite2,radioDefaultBlue,radioDefaultWhite,radioHoverBlue,radioHoverWhite&locale=all"],
    linkView: ["cases?filters[identifier][$eq]=###identifier###&populate[0]=thumbnail,banner1,banners2,goals.goals,challenge.image,solution.solutions,routes,results.image,improvements,related.cases.client,related.cases.case_type,case_type,technology.technologies,expertise,client,seo,seo.metaImage&locale=all",
    "component-menu?populate[0]=routes,logoBlue1,logoBlue2,logoWhite1,logoWhite2,radioDefaultBlue,radioDefaultWhite,radioHoverBlue,radioHoverWhite&locale=all"],
    auxView: ["aux-views?filters[identifier][$eq]=###identifier###&populate=*&locale=all", "component-menu?populate[0]=routes,logoBlue1,logoBlue2,logoWhite1,logoWhite2,radioDefaultBlue,radioDefaultWhite,radioHoverBlue,radioHoverWhite&locale=all"],
    general: ["general-configuration?populate[0]=seo,seo.metaImage&locale=all"]
  }
  return uris[component]
} 

const componentSizeClass = (component) => {
  const sizeClasses = {
    hero: containerLHero,
    heroSection: containerLHeroSection,
    verticals: containerLBlacktec,
    clients: containerSWhitec,
    conversation: conversationOutterContainer,
    footer: containerXSWhitec,
    adn: containerXLWhitec,
    about: containerMSWhitec,
    services: containerLWhitec,
    technology: containerMWhitec,
    methodology: containerMethodology,
    offices: containerXLWhitec,
    team: containerFitWhitec,
    relation: containerRelation,
    testimonials: containerMSWhitec, 
    caseStudies: containerLBluetec, 
    caseView: containerFitWhitec, 
    caseList: containerFitWhitec 
  }
  return sizeClasses[component]
}

const getImageUrl = (endpoint) => {
  return endpoint ? `${process.env.REACT_APP_STRAPI_MEDIA_URL}${endpoint}` : ''
}

const componentSelector = {
  caseView: CaseView,
  linkView: LinkView,
  auxView: AuxView
}

const mediaQueryBreakPoints = {
  // 12 columns
  xxxl: 2056,
  xxl: 1920,
  xl: 1536,
  l: 1200,
  m: 1024, 
  // 8 columns
  s: 900,
  xs: 768,
  // 6 columns
  xxs: 480,
  // 4 columns
  xxxs: 360, 
  none:0
}

const getWidthBreakpoint = (width) => {
  const size = Object.keys(mediaQueryBreakPoints).find(key => width >= mediaQueryBreakPoints[key]);
  return size || 'xxs';
}

const localeFallback = (res, loc) => {
  if (Array.isArray(res)) {
    const resDefault = res.filter(item => item.attributes.locale === 'es')
    const resLocale = res.filter(item => item.attributes.locale === loc)
    return resLocale.length === 0 ? resDefault.length === 0 ? {} : resDefault[0].attributes : resLocale[0].attributes
  }
  return res.attributes
}

const configureBody = (gtmInfo) => {
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmInfo}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);
  document.body.insertBefore(noscript, document.body.firstChild)
}

const configureRoute = (route, loc) => {
  return `${loc === 'es' ? '' : `/${loc}`}${route}`
}

export { getGridCols, getStrapiEndpoints, componentSizeClass, getImageUrl, componentSelector, 
  mediaQueryBreakPoints, getWidthBreakpoint, localeFallback, configureBody, configureRoute }