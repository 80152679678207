import { Box } from "@mui/material"

import Testimonial from "./Testimonial";

import { testimonialCarouselContainer } from "../../theme/styles"
import { useDragScroll } from "../../hooks/hooks";

export default function Carousel({array, from}) {
  const { containerRef, handleImageMouseDown } = useDragScroll();
  
  return (
    <Box sx={testimonialCarouselContainer} ref={containerRef}>
      {from === 'case' ?
        array?.map((c, i) => {
          return c && c.attributes.testimonials?.data.map((t, i) => {
            return (
              t.attributes &&
              (t.attributes.isPrincipal || from==='case') &&
                <Testimonial 
                  key={i}
                  imageUrl={t.attributes.image?.data?.attributes?.url}
                  name={t.attributes.name}
                  job={t.attributes.job}
                  testimony={t.attributes.testimonial}
                  onMouseDown={handleImageMouseDown}
                  />
          )
        })
      })
      :
      array?.map((c, i) => {
        return ( c.attributes &&
          <Testimonial 
            key={i}
            imageUrl={c.attributes.image?.data?.attributes?.url}
            name={c.attributes.name}
            job={c.attributes.job}
            onMouseDown={handleImageMouseDown}
            testimony={c.attributes.testimonial}
            />
        )
      })
      }
    </Box>
  )
}