import { Container, Grid, Typography } from "@mui/material";
import { createRef, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useTheme } from "@emotion/react";

import MethodologySVG from "./MethodologiySVG";
import GridBG from "../common/GridBG";
import longArrowDown from "../../assets/svg/longArrowDown.svg";
import bigOBlue from "../../assets/svg/bigOBlue.svg";
import bigOWhite from "../../assets/svg/bigOWhite.svg";

import { mediaQueryBreakPoints } from "../../utils/functions";

import { alignSelfFlexStart, bodyLeadWhitec, containerGrid, 
  headingLWhitec, headingSBluetec, mb20, methodologyBulletPoint, 
  methodologyHeader, methodologyInnerBulletPoint, relationArrowContainer } from "../../theme/styles";
import { useAppRefData } from "../../contexts/Contexts";

export default function Methodology({ data, width, height, elementRef }) {
  const [step, setStep] = useState(0);
  const [process, setProcess] = useState(data.processes[0]);

  const bulletRefs = useRef(data.processes.map(() => createRef()));
  const refStart = useRef(null)
  const refHeader = useRef(null)
  const theme = useTheme()
  const prevScrollY = useRef(0)
  const appRefContext = useAppRefData()
  const initialScroll = elementRef.current.offsetTop

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
    };

    const enableScroll = () => {
      document.body.style.overflow = '';
    };

    const setElementStyles = (isSticky) => {
      Array.from(appRefContext.appRef.current.children).forEach((c, i) => {
        if (i > 1) {
          c.style.position = isSticky ? 'sticky' : 'relative';
          c.style.top = isSticky ? '2000px' : 'unset';
        }
      });

      elementRef.current.style.position = isSticky ? 'sticky' : 'relative';
      elementRef.current.style.top = isSticky ? '0px' : 'unset';

      if (isSticky) {
        elementRef.current.nextSibling.style.position = 'sticky';
        elementRef.current.nextSibling.style.top = `${elementRef.current.clientHeight}px`;
      } else {
        elementRef.current.nextSibling.style.position = 'relative';
        elementRef.current.nextSibling.style.top = 'unset';
      }
    };

    const handleScroll = () => {
      const currentScrollY = document.documentElement.scrollTop;
      const elementOffsetTop = elementRef.current?.offsetTop;
      const scrollDelta = document.documentElement.scrollTop - elementOffsetTop;
      if (scrollDelta > -10 && step < (data.processes.length - 1)) {
        disableScroll();

        setTimeout(enableScroll, 1000);

        if (currentScrollY > prevScrollY.current) {
          setElementStyles(true);

          if (step < data.processes?.length - 1) {
            setStep((prevStep) => prevStep + 1);
            window.scrollTo({ top: initialScroll, behavior: 'instant' });
          } else {
            setElementStyles(false);
          }
        }

        prevScrollY.current = currentScrollY;
      } else if (scrollDelta < -10 && currentScrollY < prevScrollY.current) {
        setElementStyles(false);
        enableScroll()
        if (step > 0) {
          setStep(0);
        }

        prevScrollY.current = currentScrollY;
      } else {
        setElementStyles(false);
        enableScroll()
      }
    };

    const debouncedScroll = debounce(handleScroll, 50);

    window.addEventListener('scroll', debouncedScroll);

    return () => {
      window.removeEventListener('scroll', debouncedScroll);
    };
  }, [step, data, appRefContext.appRef, elementRef, initialScroll]);

  useEffect(() => {
    setProcess(data.processes[step]);
  }, [step, data]);

  const calculateTranslate = () => {
    if (bulletRefs.current[step] && bulletRefs.current[step].current) {
      const bulletTop = bulletRefs.current[step].current.offsetTop;
      const bulletHeight = bulletRefs.current[step].current.clientHeight;
      const headerHeight = refHeader.current.clientHeight;
      const headerTop = refHeader.current.offsetTop + refHeader.current.parentNode.parentNode.offsetTop;

      const bulletMiddle = bulletTop + (bulletHeight / 2);
      const headerMiddle = headerTop + (headerHeight / 2);
      const translation = bulletMiddle - headerMiddle;
      
      return translation;
    } 
    return 0;
  };

  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'void'} />
      {data && 
        <Grid container columns={width > mediaQueryBreakPoints.m ? 12: 8} sx={containerGrid}>
          {width > mediaQueryBreakPoints.m &&
            <Grid item xs={1} sx={width > mediaQueryBreakPoints.xl ? {} : [alignSelfFlexStart, {marginTop: '30px'}]}>
              <Container maxWidth={false} disableGutters ref={refStart}>
                {data.processes?.map((item, index) => (
                  index === 0 ? 
                  <div key={index} ref={bulletRefs.current[index]}/> : 
                  <div key={index} style={methodologyBulletPoint} ref={bulletRefs.current[index]}>
                    <div style={methodologyInnerBulletPoint}/>
                  </div>
                ))}
              </Container>
            </Grid>
          }
          <Grid item s={5} xxs={12} sx={width > mediaQueryBreakPoints.xl ? {} : alignSelfFlexStart}>
            <Container maxWidth={false} disableGutters sx={[methodologyHeader, width > mediaQueryBreakPoints.m && {transform: `translate(0, ${calculateTranslate()}px)`}]}>
              <motion.div key={step} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: .8}} exit={{opacity: 0}}>
                <Typography sx={[headingSBluetec, mb20]}>{process?.subtitle}</Typography>
                <Typography ref={refHeader} sx={[headingLWhitec, step > 0 && mb20]}>{process?.title}</Typography>
                <Typography sx={bodyLeadWhitec}>{process?.description}</Typography>
              </motion.div>
            </Container>
            {width > mediaQueryBreakPoints.xs &&
              <Container maxWidth={false} disableGutters sx={relationArrowContainer}>
                <Typography sx={bodyLeadWhitec}>{data.bottomText}</Typography>
                <img alt={""} src={longArrowDown} />
              </Container>
            }
          </Grid>
          <Grid item l={0} md={0}/>
          <Grid item l={6} s={5}>
            <Container maxWidth={false} disableGutters>
              <MethodologySVG 
                width={width}
                height={width > mediaQueryBreakPoints.xl ? height : height/2} 
                rectHeight={width > mediaQueryBreakPoints.xl ? 2 : width < mediaQueryBreakPoints.m ? 4 : 6}
                rectStart={width > mediaQueryBreakPoints.xl ? 2 : width < mediaQueryBreakPoints.m ? 1 : 0}
                gridColumns={width > mediaQueryBreakPoints.m ? 12 : width < mediaQueryBreakPoints.xs ? 6 : 8}
                step={step} 
                bigOWhite={bigOWhite}
                bigOBlue={bigOBlue}
              />
            </Container>
          </Grid>
        </Grid>
      }
    </>
  );
}
