import { createContext, useContext, useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useStrapiLocale, useStrapiSingle } from "../hooks/hooks";
import { configureBody, configureRoute, getImageUrl, getStrapiEndpoints } from "../utils/functions";
import PopUpManager from "../components/forms/PopUpManager";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const MenuOpenContext = createContext()
export const MenuOpenProvider = ({ children }) => {
	const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <MenuOpenContext.Provider value={{
      menuOpen,
      toggleMenu,
    }}>
      {children}
    </MenuOpenContext.Provider>
  );
}
export const useMenuOpenData = () => useContext(MenuOpenContext)

export const PopupVisibilityContext = createContext()
export const PopupVisibilityProvider = ({ children }) => {
  const [contactPopupVisible, setContactPopupVisible] = useState(false)
  const [appPopupVisible, setAppPopupVisible] = useState(false)
  const [formData, setFormData] = useState()
  const [data, setData] = useState(null);
  
  const dataStrapi = useStrapiSingle(getStrapiEndpoints('form'))

  const toggleContactPopup = () => setContactPopupVisible(!contactPopupVisible);

  useEffect(() => {
    if (contactPopupVisible || appPopupVisible){
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [contactPopupVisible, appPopupVisible])

  useEffect(() => {
    setData(dataStrapi);
  }, [dataStrapi]);

  const toggleAppPopup = (formData) => {
    setFormData(formData)
    setAppPopupVisible(!appPopupVisible)
  }

  return (
    <PopupVisibilityContext.Provider value={{
      contactPopupVisible,
      appPopupVisible,
      formData,
      toggleContactPopup,
      toggleAppPopup
    }}>
      {data && <PopUpManager data={data} />}
      {children}
    </PopupVisibilityContext.Provider>
  );
}
export const usePopupVisibility = () => useContext(PopupVisibilityContext);

export const LanguageContext = createContext()
export const LanguageProvider = ({ children }) => {
  const [languageSelected, setLanguageSelected] = useState(Cookies.get('lang') === undefined ? 'es' : Cookies.get('lang'))
  const [locales, setLocales] = useState([])

  const navigate = useNavigate()
  const location = useLocation()
  const routesContext = useRoutesData()

  const localeStrapi = useStrapiLocale(getStrapiEndpoints('locale'));

  useEffect(() => {
    if (Cookies.get('lang') === undefined){
      Cookies.set('lang', 'es')
      setLanguageSelected('es')
    } else {
      setLanguageSelected(Cookies.get('lang'))
    }
  }, []);

  useEffect(() => {
    if (locales) {
      const localeList = locales.map(locale => locale.code)
      const regExLocales = new RegExp(`^/(${localeList.join('|')})`)
      const localeFromRoute = location.pathname.match(regExLocales)
      if (localeFromRoute) {
        setLanguageSelected(localeFromRoute[1])
      } else {
        setLanguageSelected('es')
      }
    }
  }, [location.pathname, locales])

  useEffect(() => {
    setLocales(localeStrapi);
  }, [localeStrapi]);

  useEffect(() => {
    if (Cookies.get('lang') === undefined){
      Cookies.set('lang', 'es')
    } else {
      Cookies.set('lang', languageSelected)
    }
  }, [languageSelected]);

  const extractSlug = (path) => {
    const parts = path.split('/').filter(Boolean);
    const languageCodes = locales.map(language => language.code);
    if (languageCodes.includes(parts[0])) {
      return `/${parts.slice(1).join('/')}`;
    }
    return `/${parts.join('/')}`;
  }

  const handleSetLocale = (loc) => {
    const oldLoc = Cookies.get('lang')
    if (loc !== oldLoc) {
      setLanguageSelected(loc)
      const currentRoute = routesContext.routes?.data?.find(obj => obj.attributes?.route === extractSlug(location.pathname) && obj.attributes?.locale === oldLoc)
      if (currentRoute) {
        const targetRoute = routesContext.routes.data.find(obj => 
          obj.attributes?.identifier === currentRoute.attributes?.identifier && obj.attributes?.locale === loc
        );
        if (targetRoute) {
          navigate(configureRoute(targetRoute.attributes?.route, loc))
        }
      } else {
        navigate(configureRoute(location.pathname.replace(`/${oldLoc}`, ''), loc))
      }
    }
  }

  return (
    <LanguageContext.Provider value={{
      languageSelected,
      locales,
      setLanguageSelected,
      handleSetLocale
    }}>
      {children}
    </LanguageContext.Provider>
  )
}
export const useLanguageData = () => useContext(LanguageContext)

export const AppRefContext = createContext()
export const AppRefProvider = ({ children }) => {
  const appRef = useRef(null)
  return (
    <div ref={appRef}>
      <AppRefContext.Provider value={{appRef}}>
        {children}
      </AppRefContext.Provider>
    </div>
  )
}
export const useAppRefData = () => useContext(AppRefContext)

export const RoutesContext = createContext()
export const RoutesProvider = ({ children }) => {
  const [routes, setRoutes] = useState(null);
  const [redirects, setRedirects] = useState(null);
  const strapiRoutes = useStrapiLocale(getStrapiEndpoints('routes'))
  const strapiRedirects = useStrapiLocale(getStrapiEndpoints('redirects'))

  useEffect(() => {
    setRoutes(strapiRoutes);
    setRedirects(strapiRedirects);
  }, [strapiRoutes, strapiRedirects]); 
  
  return (
    <RoutesContext.Provider value={{
      routes,
      redirects
    }}>
      {children}
    </RoutesContext.Provider>
  )
}
export const useRoutesData = () => useContext(RoutesContext)

export const HelmetContext = createContext()
export const HelmetContextProvider = ({ children }) => {
  const [data, setData] = useState()
  const [allSet, setAllSet] = useState()
  const [seoTitle, setSeoTitle] = useState(null)
  const [seoDescription, setSeoDescription] = useState(null)
  const [canonicalUrl, setCanonicalUrl] = useState(null)
  const [keywords, setKeywords] = useState(null)
  const [robots, setRobots] = useState(null)
  const [viewport, setViewport] = useState(null)
  const [metaImage, setMetaImage] = useState(null)

  const dataStrapi = useStrapiSingle(getStrapiEndpoints('general'))

  useEffect(() => {
    setData(dataStrapi)
  }, [dataStrapi])

  useEffect(() => {
    if (data && !allSet) {
      configureBody(data.googleTagManager)
      setAllSet(true)
    }
  }, [data, allSet])

  return (
    <HelmetContext.Provider value={{
      seoTitle,
      setSeoTitle,
      seoDescription,
      setSeoDescription,
      canonicalUrl,
      setCanonicalUrl,
      keywords,
      setKeywords,
      robots,
      setRobots,
      viewport,
      setViewport,
      metaImage,
      setMetaImage
    }}>
      <HelmetProvider>
        <Helmet>
          {data && <script type="text/javascript" src={`https://cookie-cdn.cookiepro.com/consent/${data.cookieDataDomainScript}/OtAutoBlock.js`} ></script>}
          {data && <script src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script={data.cookieDataDomainScript} ></script>}
          {data && <script type="text/javascript">{`function OptanonWrapper() { }`}</script>}
          {data &&
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${data.googleTagManager}');`}</script>
          }
          {data && <title>{seoTitle ? seoTitle : data.seo ? data.seo?.metaTitle : 'Brooktec'}</title>}
          {data?.seo?.metaDescription && <meta name="description" content={seoDescription ? seoDescription : data.seo ? data.seo?.metaDescription : ''}/>}
          {data?.seo?.canonicalURL && <link rel="canonical" href={canonicalUrl ? canonicalUrl : data.seo ? data.seo?.canonicalURL : ''} />}
          {data?.seo?.keywords && <meta name="keywords" content={keywords ? keywords : data.seo ? data.seo?.keywords : ''} />}
          {data?.seo?.metaRobots && <meta name="robots" content={robots ? robots : data.seo ? data.seo?.metaRobots : ''}/>}
          {data?.seo?.metaViewport && <meta name="viewport" content={viewport ? viewport : data.seo ? data.seo?.metaViewport : ''} />}
          {data?.seo?.metaImage && <meta property="og:image" content={metaImage ? metaImage : data.seo ? getImageUrl(data.seo?.metaImage?.data?.attributes?.url) : ''} />}
          <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        </Helmet>
      </HelmetProvider>           
      {children}
    </HelmetContext.Provider>
  )
}
export const useHelmetData = () => useContext(HelmetContext)

export const FilterContext = createContext()
export const FilterProvider = ({ children }) => {
  const [filter, setFilter] = useState('all')

  return (
    <FilterContext.Provider value={{
      filter,
      setFilter
    }}>
      {children}
    </FilterContext.Provider>
  )
}
export const useFilterData = () => useContext(FilterContext)