import { Grid } from "@mui/material";
import { BGGrid } from "../../theme/styles";
import { getWidthBreakpoint } from "../../utils/functions";

export default function OverGrid({ width, height, pattern, cols, patternImage }) {
  const wBP = getWidthBreakpoint(width)
  return (
    <Grid sx={BGGrid} container columns={cols}>
      <PatternBuilder height={height} width={width} pattern={pattern[wBP]} cols={cols} patternImage={patternImage}/>
    </Grid>
  );
}

function PatternBuilder({ height, width, pattern, cols, patternImage }) {
  const vpX = 0.75 * width;
  const vpY = height;
  const rows = 6;

  const shapes = {
    rect: SvgRect,
    bigO: BigO
  };

  return (
    <svg style={{ overflow: 'visible', position: "relative" }} width={vpX} height={vpY} viewBox={`0 0 ${vpX} ${vpY}`} xmlns="http://www.w3.org/2000/svg">
      {
        pattern.map((p, i) => {
          const Shape = shapes[p.type];
          return (
            <g key={i}>          
              <Shape rect={p} vpX={vpX} vpY={vpY} columns={cols} rows={rows} patternImage={patternImage}/>
            </g>
          );
        })
      }
    </svg>
  );
}

function SvgRect({ rect, vpX, vpY, columns, rows }) {
  return (
    <rect
      x={rect.position.x * vpX / columns}
      y={rect.position.y * vpY / rows}
      height={rect.shape.height * vpY / rows}
      width={rect.shape.width * vpX / columns}
      stroke={rect.stroke} 
      strokeWidth={1.5}
      fill={rect.fill}
      style={{ transition: 'all .8s' }}
    />
  );
}

function BigO({ rect, vpX, vpY, columns, rows, patternImage }) {
  return (
    <image 
      x={rect.position.x * vpX / columns}
      y={rect.position.y * vpY / rows}
      height={rect.shape.height * vpY / rows}
      width={rect.shape.width * vpX / columns}
      href={patternImage}
      style={{ transition: 'all .8s' }}
    />
  );
}
