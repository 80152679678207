import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../../../common/GridBG";
import ModuleHeader from "../../../common/ModuleHeader";

import { caseContainer, caseTestimonialsText, containerFitBlack, headingSBluetec } from "../../../../theme/styles";

export default function CaseTestimonials({testimonials, width}) {
  const theme = useTheme()
  return (
    <Container disableGutters maxWidth={false} sx={containerFitBlack}>
      <GridBG 
        width={width} 
        height={600} 
        color={theme.palette.bluetec20} 
        pattern={'void'} 
        />
      <Grid container sx={caseContainer}>
        <Grid item l={3} s={1}/>
        <Grid item l={6} s={11}>
          {testimonials.map((t, i) => {
            return (
              t && t.attributes &&
              <ModuleHeader 
                key={i}
                title={t.attributes.testimonial} 
                titleClass={caseTestimonialsText}
                subtitle={`${t.attributes.name}. ${t.attributes.job}`}
                subtitleClass={headingSBluetec}
                />
            )
          })}
        </Grid>
      </Grid>
    </Container>
  );
}