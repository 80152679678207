import { useState } from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { footerLink, upperCase } from "../../theme/styles";

export default function Links({ to, text, textClass }) {
  const [hovered, setHovered] = useState(false);
  const theme = useTheme()

  const setHover = () => {
    setHovered(true);
  };

  const setNotHover = () => {
    setHovered(false);
  };

  return (
    <Link
      style={footerLink}
      onMouseOver={setHover}
      onMouseOut={setNotHover}
      to={to}
    >
      <Typography sx={[textClass, upperCase]}>{text}</Typography>
      {hovered && <div style={{ zIndex: 4, width: '5px', height: '5px', background: theme.palette.bluetec }} />}
    </Link>
  );
}
