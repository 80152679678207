import { Container, Typography, Avatar } from "@mui/material"
import { motion, AnimatePresence } from "framer-motion"

import { dropdownAnimation } from "../../utils/animations"
import downChevron from "../../assets/svg/downChevronWhite.svg";

import { alignItemsCenter, bodyTextServices, bodyTextServicesTitle, 
  bodyTextSGrey, flexColumn, headingMWhite, mb20, serviceDropdown, 
  serviceDropdownText, servicesAvatarArrow, servicesBadgeContainer } from "../../theme/styles"


export default function Dropdown({ badge, title, text, textExpanded, isOpen, onToggle }) {

  return (
    <Container disableGutters onClick={onToggle} sx={[serviceDropdown, mb20]}>
      <Container disableGutters sx={alignItemsCenter}>
        <Container disableGutters sx={servicesBadgeContainer}>
          <Typography sx={headingMWhite}>{badge}</Typography>
        </Container>
        <Container sx={flexColumn}>
          <Typography sx={bodyTextServicesTitle}>{title}</Typography>
          <Typography sx={bodyTextSGrey}>{text}</Typography>
        </Container>
        <Avatar id={'arrow'} sx={[servicesAvatarArrow, { transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }]}>
          <img alt={''} src={downChevron} />
        </Avatar>
      </Container>
      <AnimatePresence>
        {isOpen &&
          <Container
            disableGutters sx={serviceDropdownText}
            component={motion.div}
            initial={dropdownAnimation.initial}
            animate={dropdownAnimation.animate}
            exit={dropdownAnimation.exit}
            transition={dropdownAnimation.transition}
          >
            <Typography sx={bodyTextServices}>{textExpanded}</Typography>
          </Container>
        }
      </AnimatePresence>
    </Container>
  )
}
