import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    transparent: '#ffffff00',
    whitec: '#f0f0f0',
    whitec20: '#f0f0f020',
    whitec40: '#f0f0f040',
    whitec60: '#f0f0f060',
    whitecHover: '#dddddd',
    white: '#ffffff',
    white20: '#ffffff20',
    white40: '#ffffff40',
    white60: '#ffffff60',
    blackCode: '#000000',
    blacktec: '#101010',
    greytec: '#bec2c9',
    grey1: '#4a4a4a',
    grey2: '#888686',
    bluetec: '#005bff',
    bluetec10: '#005bff10',
    bluetec20: '#005bff25',
    bluetec40: '#005bff40',
    bluetecWhite: '#2f75f7',
    greentec: '#13e0a440',
    green: '#13e0a4',
    purpletec: '#6933d030',
    yellowtec: '#ffeb9a',

    btnHover: '#317AFF',
    cardDropdown: '#ACBBD520',
    cardDropdownHover: '#ACBBD545'
  },
  breakpoints: {
    values: {
      // 4 columns
      none:0,
      xxxs: 360,
      // 6 columns
      xxs: 480,
      // 8 columns
      xs: 768,
      s: 900,
      // 12 Columns
      m: 1024, 
      l: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2056
    }
  }
});

export default theme