import { useTheme } from "@emotion/react"
import { SvgRect, BigO } from "../common/SvgPaint"

export default function RelationSVG({width, height, step, bigO, rectStart, rectHeight, gridColumns}) {
  const theme = useTheme()
  const vpX = 0.75 * 6 * width / gridColumns
  const vpY = height
  const columns = 6
  const rows = 6
  const steps = {
    rect1: {
      positions: {
        0: {x: 0, y: rectStart + 2.5},
        1: {x: 0, y: rectStart + 1.5},
        2: {x: 0, y: rectStart + 1},
        3: {x: 0, y: rectStart + 1},
      },
      shapes: {
        0: {width: 1, height: rectHeight},
        1: {width: 1, height: rectHeight},
        2: {width: 1, height: rectHeight},
        3: {width: 1, height: rectHeight},
      },
      styles: {
        0: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
        1: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
        2: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
        3: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
      }
    },
    rect2: {
      positions: {
        0: {x: 1, y: rectStart + 1.5},
        1: {x: 1, y: rectStart},
        2: {x: 1, y: rectStart},
        3: {x: 1, y: rectStart},
      },
      shapes: {
        0: {width: 1, height: rectHeight/2},
        1: {width: 1, height: rectHeight/2},
        2: {width: 1, height: rectHeight/2},
        3: {width: 1, height: rectHeight/2},
      },
      styles: {
        0: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        1: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        2: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        3: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
      }
    },
    rect3: {
      positions: {
        0: {x: 2, y: rectStart },
        1: {x: 2, y: rectStart + 3.2},
        2: {x: 2, y: rectStart + 2.7},
        3: {x: 2, y: rectStart + 1},
      },
      shapes: {
        0: {width: 1, height: rectHeight/2},
        1: {width: 1, height: rectHeight/2},
        2: {width: 1, height: rectHeight/2},
        3: {width: 1, height: rectHeight/2},
      },
      styles: {
        0: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        1: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        2: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        3: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
      }
    },
    rect4: {
      positions: {
        0: {x: 2, y: rectStart + 3.4},
        1: {x: 4, y: rectStart + .2},
        2: {x: 4, y: rectStart + 1.5},
        3: {x: 4, y: rectStart + 3.5},
      },
      shapes: {
        0: {width: 1, height: rectHeight/2},
        1: {width: 1, height: rectHeight/2},
        2: {width: 1, height: rectHeight/2},
        3: {width: 1, height: rectHeight/2},
      },
      styles: {
        0: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
        1: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
        2: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
        3: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20},
      }
    },
    rect5: {
      positions: {
        0: {x: 3, y: rectStart + 2},
        1: {x: 3, y: rectStart + 2.5},
        2: {x: 3, y: rectStart + 2.5},
        3: {x: 3, y: rectStart + .5},
      },
      shapes: {
        0: {width: 1, height: rectHeight},
        1: {width: 1, height: rectHeight},
        2: {width: 1, height: rectHeight},
        3: {width: 1, height: rectHeight},
      },
      styles: {
        0: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        1: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        2: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
        3: {stroke: theme.palette.bluetec, fill: theme.palette.transparent},
      }
    },
    rect6: {
      positions: {
        0: {x: 5, y: rectStart + 2.5},
        1: {x: 5, y: rectStart + 1.2},
        2: {x: 5, y: rectStart + 2.5},
        3: {x: 5, y: rectStart + 2.5},
      },
      shapes: {
        0: {width: 1, height: rectHeight},
        1: {width: 1, height: rectHeight},
        2: {width: 1, height: rectHeight},
        3: {width: 1, height: rectHeight},
      },
      styles: {
        0: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec},
        1: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec},
        2: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec},
        3: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec},
      }
    },
    bigO: {
      positions: {
        0: {x: 4.5, y: rectStart + 1},
        1: {x: 1.5, y: rectStart + 1.2},
        2: {x: 4.5, y: rectStart},
        3: {x: 1.5, y: rectStart + 2.5}
      },
      shape: {
        width: 1.5,
        height: 1.5
      }
    }
  }
  return(
    <svg style={{overflow: 'visible', position: "relative"}} width={vpX} height={vpY} viewBox={`0 0 ${vpX} ${vpY}`} xmlns="http://www.w3.org/2000/svg">
      <SvgRect rect={steps.rect1} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
      <SvgRect rect={steps.rect2} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
      <SvgRect rect={steps.rect3} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
      <SvgRect rect={steps.rect4} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
      <SvgRect rect={steps.rect5} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
      <SvgRect rect={steps.rect6} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
      <BigO rect={steps.bigO} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step} bigO={bigO}/>
    </svg>
  )
} 