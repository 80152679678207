import { Navigate, Route, Routes } from "react-router-dom"
import { useLanguageData, useRoutesData } from "../contexts/Contexts"
import React, { useEffect, useState } from "react"
import { configureRoute, getImageUrl } from "../utils/functions"

import Home from '../views/Home';
import Us from '../views/Us';
import Experience from '../views/Experience';
import Clients from '../views/Clients';
import Contact from '../views/Contact';
import CaseView from '../views/CaseView';
import LinkView from '../views/LinkView';
import AuxView from '../views/AuxView';
import NotFound from "../components/notFound/NotFound";

export default function RouteManager() {
  const [isLoading, setIsLoading] = useState(true);
  const routesContext = useRoutesData()
  const languageContext = useLanguageData()

  useEffect(() => {
    if (routesContext.routes && languageContext.locales && routesContext.redirects) {
      setIsLoading(false);
    }
  }, [routesContext.routes, routesContext.redirects, languageContext.locales]);

  return (
    !isLoading &&
    <Routes>
      <Route path='/' element={<Home />}/>
      {routesContext.routes && 
      routesContext.routes.data?.map((r, i) => {
        if (r.attributes) {
          const props = {
            title: r.attributes.seo?.metaTitle,
            description: r.attributes.seo?.metaDescription,
            url: r.attributes.seo?.canonicalURL,
            keywords: r.attributes.seo?.keywords,
            robots: r.attributes.seo?.metaRobots,
            viewport: r.attributes.seo?.metaViewport,
            image: getImageUrl(r.attributes.seo?.metaImage?.data?.attributes?.url)
          }
          switch(r.attributes.identifier) {
            case 'us':
              return <Route key={i} 
                path={configureRoute(r.attributes.route, r.attributes.locale)} 
                element={<Us {...props}/>}/>
            case 'experience':
              return <Route key={i} 
                path={configureRoute(r.attributes.route, r.attributes.locale)} 
                element={<Experience {...props}/>}/>
            case 'clients':
              return <Route key={i} 
                path={configureRoute(r.attributes.route, r.attributes.locale)} 
                element={<Clients {...props} />}/>
            case 'contact':
              return <Route key={i} 
                path={configureRoute(r.attributes.route, r.attributes.locale)} 
                element={<Contact {...props} />}/>
            default: 
              if (/\/info\//.test(r.attributes.route)) {
                return <Route key={i} 
                  path={configureRoute(r.attributes.route, r.attributes.locale)} 
                  element={<AuxView name={r.attributes.identifier} {...props}/>}/>
              }
              return null
          }
        }
        return null
      })}
      {languageContext.locales &&
      languageContext.locales.map((l, i) => {
        return(
          <React.Fragment key={i}>
            <Route path={configureRoute('/', l.code)} element={<Home/>}/>
            <Route path={configureRoute(`/case/:caseName`, l.code)} element={<CaseView/>}/>
            <Route path={configureRoute(`/link/:linkName`, l.code)} element={<LinkView/>}/>
            <Route path={configureRoute(`/news/:linkName`, l.code)} element={<LinkView/>}/>
          </React.Fragment>
        )
      })}
      <Route path='*' element={<NotFound/>}/>
      {routesContext.redirects &&
      routesContext.redirects.redirects?.map((r, i) => {
        const navigateProps = {to: r?.to, replace: r?.type?.match(/\d+/)[0]}
        return(
          r &&
          <Route key={i} path={r.from} element={
            <Navigate {...navigateProps} />
          }/>
        )
      })
      }
    </Routes>
  )
}