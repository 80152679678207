import { Container, Input, MenuItem, Select, Button, Typography } from "@mui/material"

import rightArrow from '../../assets/svg/rightArrow.svg';

import { formApplicationContainer, formButtonSubmit, formFileSelectorContainer, 
  formInput, mainButtonText } from "../../theme/styles"

import { useState } from "react";
import CheckBoxForm from "./CheckBoxForm";
import Recaptcha from "./Recaptcha";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTheme } from "@emotion/react";


export default function FormFields({array, handleChange, handleContact, buttonText, fileName, errorEmailAlert, errorPhoneAlert, errorTypeAlert, errorFileAlert, recaptchaRef, recaptchaKey, typeValue, checkBoxText}) {
  const [isChecked, setIsChecked] = useState(false);
  const theme = useTheme()

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  
  return(
    <Container disableGutters sx={formApplicationContainer} >
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        {array?.map((o, i) => {
          return (
            o.attributes &&
            o.attributes.type === 'input' ?
            <div key={i}>
            <Input
              id={o.attributes.identifier} 
              placeholder={o.attributes.placeholder} 
              onChange={handleChange} 
              sx={[formInput, ((o.attributes.identifier === 'email' && errorEmailAlert) || (o.attributes.identifier === 'phone' && errorPhoneAlert)) && {border: '1px solid red'}]}/>
              {o.attributes.identifier === 'email' && errorEmailAlert && <Typography style={{color: 'white', fontSize: 12}}><span style={{color: 'red'}}>{'* '}</span>{errorEmailAlert}</Typography>}
              {o.attributes.identifier === 'phone' && errorPhoneAlert && <Typography style={{color: 'white', fontSize: 12}}><span style={{color: 'red'}}>{'* '}</span>{errorPhoneAlert}</Typography>}
            </div> :
            o.attributes.type === 'textField' ?
            <Input key={i} 
              id={o.attributes.identifier} 
              multiline={true}
              rows={4} 
              placeholder={o.attributes.placeholder} 
              onChange={handleChange} sx={formInput}/> :
            o.attributes.type === 'selector' ?
            <div key={i}>
            <Select
              id={o.attributes.identifier} 
              placeholder={o.attributes.placeholder} 
              onChange={handleChange} 
              value={typeValue ? typeValue : ''}
              style={{margin: 0, padding: '8px 12px'}}
              sx={[formInput, (o.attributes.identifier === 'companyType' && errorTypeAlert) && {border: '1px solid red'}]}>
              {o.attributes.expertise?.data?.map((e, ix) => {
                return (
                  <MenuItem 
                    key={ix} 
                    value={e.attributes.name}>
                    {e.attributes.name}
                  </MenuItem>
                )
              })}
            </Select>
            {o.attributes.identifier === 'companyType' && errorTypeAlert && <Typography style={{color: 'white', fontSize: 12}}><span style={{color: 'red'}}>{'* '}</span>{errorTypeAlert}</Typography>}
            </div>
            :
            o.attributes.type === 'file' ?
            <div key={i}>
            <Container 
              disableGutters 
              onClick={() => {document.getElementById(o.attributes.identifier).click()}} 
              sx={[formFileSelectorContainer, o.attributes.identifier === 'cv' && errorFileAlert && {border: '1px solid red'} ]}
              >
              <Typography style={{width: 'unset'}} sx={formInput}>{fileName === '' ? o.attributes.placeholder: fileName}</Typography>
              <input 
                style={{display: 'none'}} 
                type="file"
                id={o.attributes.identifier} 
                rows={4} 
                placeholder={o.attributes.placeholder} 
                onChange={handleChange} sx={formInput}/>
            </Container>
            {o.attributes.identifier === 'cv' && errorFileAlert && <Typography style={{color: 'white', fontSize: 12}}><span style={{color: 'red'}}>{'* '}</span>{errorFileAlert}</Typography>}
            </div>
            :
            <></>
          )
        })}
        <Recaptcha ref={recaptchaRef} />
        <CheckBoxForm isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} checkBoxText={checkBoxText}/>
        <Button 
          disabled={!isChecked}
          sx={[formButtonSubmit, {background: !isChecked && theme.palette.whitec60}]}
          onClick={handleContact}>
          <Typography sx={mainButtonText}>{buttonText}</Typography>
          &nbsp;&nbsp;&nbsp;
          <img alt={''} src={rightArrow} />
        </Button>
      </GoogleReCaptchaProvider>
    </Container>
  )
}
