import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Container } from "@mui/material";

import CaseIntroduction from "./components/introduction/CaseIntroduction";
import Menu from "../menu/Menu";
import CaseGoals from "./components/goals/CaseGoals";
import CaseBanner1 from "./components/banner1/CaseBanner1";
import CaseChallenge from "./components/challenge/CaseChallenge";
import CaseBanners2 from "./components/banners2/CaseBanners2";
import CaseTestimonials from "./components/testimonials/CaseTestimonials";
import CaseTechnology from "./components/technology/CaseTechnology";
import CaseSolution from "./components/solution/CaseSolution";
import CaseClientTestimonials from "./components/testimonials/CaseClientTestimonials";
import CaseResults from "./components/results/CaseResults";
import CaseImprovements from "./components/improvements/CaseImprovements";
import CaseRelated from "./components/related/CaseRelated";

import { getImageUrl } from "../../utils/functions";

import { containerFitWhitec, containerMenuWhitec, flexColumn } from "../../theme/styles";
import { useHelmetData } from "../../contexts/Contexts";

export default function CaseView({data}) {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const elementRef = useRef(null);
  const context = useHelmetData();

  const useWindowSize  = () => {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([elementRef.current.offsetWidth, elementRef.current.offsetHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize(elementRef)
  useEffect(() => {
    setSize([elementRef.current.offsetWidth, elementRef.current.offsetHeight]);
    if (data.seo) {
      context.setSeoTitle(data.seo?.metaTitle)
      context.setSeoDescription(data.seo?.metaDescription)
      context.setKeywords(data.seo?.keywords)
      context.setRobots(data.seo?.metaRobots)
      context.setRobots(data.seo?.metaViewport)
      context.setCanonicalUrl(data.seo?.canonicalURL)
      context.setMetaImage(getImageUrl(data.seo?.metaImage?.data?.attributes?.url))
    } else {
      context.setSeoTitle(`${data.case_type?.data?.attributes?.name} - ${data.title}`)
    }
  }, [data, context])

  return (
    data && width && height &&
    <Container ref={elementRef} disableGutters maxWidth={false} sx={[containerFitWhitec, flexColumn]} >
      <Container maxWidth={false} sx={containerMenuWhitec}>
        <Menu 
          width={width} 
          data={data.menu} 
          option={'caseView'} 
          />
      </Container>
      <CaseIntroduction 
        data={data.client?.data?.attributes} 
        expertise={data.expertise?.data?.attributes} 
        from ={'Case'}
        routes={data.anchors?.data}
        width={width}
        height={height}/>
      {data.banner1?.data && 
      <CaseBanner1 
        imageUrl={getImageUrl(data.banner1?.data?.attributes?.url)}
        />
      }
      {data.goals && data.goals.goals?.length > 0 && 
      <CaseGoals 
        goals={data.goals} 
        />
      }
      {data.challenge && 
      <CaseChallenge 
        challenge={data.challenge} 
        width={width}
        />
      }
      {data.banners2?.data?.length > 0 &&
      <CaseBanners2
        banners={data.banners2?.data}
        />
      }
      {data.testimonials?.data?.length > 0 && 
      <CaseTestimonials
        testimonials={data.testimonials?.data}
        width={width}
        />
      }
      {data.technology &&
      <CaseTechnology 
        tech={data.technology}
        />
      }
      {data.solution && data.solution?.solutions?.length > 0 && 
      <CaseSolution
        solution={data.solution}
        width={width}
        /> 
      }
      {data.client?.data?.attributes?.testimonials?.data?.length > 0 && 
      <CaseClientTestimonials
        client={[data.client?.data]}
        width={width}
        />
      }      
      {data.results && 
      <CaseResults
        result={data.results}
        />}
      {data.improvements?.length > 0 && 
      <CaseImprovements
        improvements={data.improvements}
        />}
      {data.related?.cases?.data?.length > 0 && 
      <CaseRelated
        caseType={data.expertise?.data?.attributes?.name}
        related={data.related}
        /> } 
    </Container>
  )
}