import { Box } from "@mui/material";

import CaseItem from "./CaseItem";

import { caseCarousselBox } from "../../theme/styles";
import { useDragScroll } from "../../hooks/hooks";


export default function Carousel({ array }) {
  const { containerRef, handleImageMouseDown } = useDragScroll();
  return (
    <Box sx={caseCarousselBox} ref={containerRef}>
      {array?.map((c, i) => (
        <CaseItem key={i} c={c} 
        onMouseDown={handleImageMouseDown}/>
      ))}
    </Box>
  );
}
