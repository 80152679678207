import { motion } from "framer-motion";
import { keyframes } from "@emotion/react";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

function HorizontalLine({theme, width, state}) {
  return (
    <motion.svg width="100%" height="10"> 
      <motion.line
      x1="0"
      y1="5"
      x2={width/12}
      y2="5"
      stroke={theme.palette.bluetec}
      strokeWidth={2}
      initial="hidden"
      animate={state ? 'hidden' : 'visible'}
      variants={draw}/>
    </motion.svg>
  )
}

const dropdownAnimation = {
  initial: { 
    opacity: 0, 
    height: 0, 
    marginTop: '0px'
  },
  animate: { 
    opacity: 1, 
    height: 'auto', 
    marginTop: '10px'
  },
  exit: { 
    opacity: 0, 
    height: 0, 
    marginTop: '0px'
  },
  transition: {
    type: 'spring',
    bounce: 0
  }
}

const expertiseAndTechnologyAnimation = {
  type: 'spring', 
  bounce: 0
}

const selectorAnimation = {
  initial: { transform: "scale(0)" },
  animate: { transform: "scale(1)" },
  exit: { transform: "scale(0)" },
}

const slide = keyframes`
    0% { left: 0; }
    100% { left: -100%; }
  `

const popUpAnimation = {
  hidden: { left: '-100%' },
  visible: { left: '50%' },
  transition: { type: 'spring', stiffness: 30, bounce: 0}
}

const popUpAnimationMenu = {
  hidden: { left: '100%' },
  visible: { left: '0%' },
  transition: { type: 'spring', stiffness: 30, bounce: 0}
}

export {
  HorizontalLine, slide, dropdownAnimation, selectorAnimation, popUpAnimation, popUpAnimationMenu, expertiseAndTechnologyAnimation
}