import { Container, Grid } from "@mui/material";

import { getImageUrl } from "../../../../utils/functions";

import { backgroundImage, containerFitBlack, caseContainer, 
  mb40, containerCaseBanner2 } from "../../../../theme/styles";

export default function CaseBanners2({banners}) {
  return (
    <Container disableGutters maxWidth={false} sx={containerFitBlack}>
      <Grid container sx={caseContainer}>
        {banners?.map((b, i) => {
          return (
            b &&
            <Container 
              key={i}
              disableGutters 
              maxWidth={false} 
              sx={[containerCaseBanner2, mb40, {background: `url(${getImageUrl(b.attributes?.url)})`}, backgroundImage]} />
          )
        })}
      </Grid>
    </Container>
  );
}