import { Grid, Container } from "@mui/material";

import ModuleHeader from "../../../common/ModuleHeader";

import { headingSBluetec, headingLBluetec, headingMBlack, 
  bodyTextBlack, caseContainer, caseGoalsContainer, 
  caseGoalContainer } from "../../../../theme/styles";

export default function CaseGoals({goals}) {
  // eslint-disable-next-line
  const sortedGoals = goals?.goals.sort((a, b) => {
    if (a.num < b.num) {
      return -1
    }
  })

  return(
    <Grid container sx={caseContainer} id={'goals'}>
      <Grid item l={3} s={1} xxxs={0}/>
      <Grid item l={9} s={11}>
        <Container disableGutters maxWidth={false}>
          <ModuleHeader 
            title={goals?.title} 
            titleClass={headingSBluetec}
          />
        </Container>
          <Container disableGutters sx={caseGoalsContainer}>
            {sortedGoals?.map((g, i) => {
              return(
                g &&
              <Grid item key={i} l={5.5} s={5.5}>
                <Container disableGutters sx={caseGoalContainer}> 
                  <ModuleHeader
                    title={g.num}
                    titleClass={headingLBluetec}
                    subtitle={g.title}
                    subtitleClass={headingMBlack}
                    description={g.description}
                    descriptionClass={bodyTextBlack}
                    />
                </Container>
              </Grid>
              )
            })}
          </Container>
      </Grid>
    </Grid>
  );
};