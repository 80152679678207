import { Container, Card, Typography, Avatar } from "@mui/material"

import { getImageUrl} from "../../utils/functions"

import { bodyTextWorkersTitle, bodyTextSGrey, mb20, flexColumn, teamWorkerCard, teamWorkerAvatar } from "../../theme/styles"

export default function Worker(props) {
  return (
    <Card sx={[teamWorkerCard, mb20]}>
      <Avatar src={getImageUrl(props.avatar)} sx={teamWorkerAvatar}/>
      <Container sx={flexColumn}>
        <Typography sx={bodyTextWorkersTitle}>{props.name}</Typography>
        <Typography sx={bodyTextSGrey}>{props.job}</Typography>
      </Container>
    </Card>
  )
} 