import { Grid, Container, Typography } from "@mui/material"
import { bodyLeadWhitec, headingXXXLWhitec, improvementType1Container, 
  improvementType2Container, improvementsHeadingMWhitec, mb20, mb40 } from "../../../../theme/styles"

export default function Improvement({improvement}) {
  return (
    improvement &&
    <>
    <Grid item l={3}>
      {improvement.type === 1 ? 
        <Container disableGutters sx={improvementType1Container}> 
          <Typography sx={[headingXXXLWhitec, mb40]}>{improvement.subtitle}</Typography>
          <Typography sx={improvementsHeadingMWhitec}>{improvement.description}</Typography>
        </Container>
        :
        <Container disableGutters sx={improvementType2Container}> 
          <Typography sx={[improvementsHeadingMWhitec, mb20]}>{improvement.subtitle}</Typography>
          <Typography sx={bodyLeadWhitec}>{improvement.description}</Typography>
        </Container>
      }
    </Grid>
    <Grid item l={.2}/>
    </>
  )
}