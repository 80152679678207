import { Grid, Button, Typography } from "@mui/material";
import { useTheme } from '@emotion/react';

import { alignItemsCenter, buttonVertical, buttonVerticalActive, buttonVerticalTextWhite, mb20 } from "../../theme/styles";

import { HorizontalLine } from '../../utils/animations'; 
import { mediaQueryBreakPoints } from "../../utils/functions";

export default function Buttons({ onClick, title, index, width, state, textClass }) {
  const theme = useTheme();

  const toggle = !state;

  const handleClick = () => {
    onClick(index);
  }

  return (
    <Grid container sx={[alignItemsCenter, mb20]} columns={3}>
      <Grid item l={2} xxs={3} style={{height: '100%'}}>
        <Button onClick={handleClick} sx={[buttonVertical, state && buttonVerticalActive, {height: '100%'}]}>
          <Typography sx={[{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}, !toggle ? buttonVerticalTextWhite : textClass]}>{title}</Typography>
        </Button>
      </Grid>
      {
        width > mediaQueryBreakPoints.l &&
        <Grid item l={1} s={0}>
          <HorizontalLine theme={theme} width={width} state={toggle} />
        </Grid>
      }
    </Grid>
  );
}
