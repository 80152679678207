import { Container } from "@mui/material";

import { getImageUrl } from "../../utils/functions";

import { headingSBluetec, headingLBlack, bodyLeadBlack, 
  backgroundImage, conversationContainer, conversationOutterContainer } from "../../theme/styles";

import ModuleHeader from "../common/ModuleHeader";
import { usePopupVisibility } from "../../contexts/Contexts";

export default function Conversation({ data }) {
  const context = usePopupVisibility()
  const handleContactClick = () => {
    context.toggleContactPopup()
  }
  return (
    <>
      {data && 
        <Container 
          disableGutters
          maxWidth={false} 
          sx={[conversationOutterContainer, backgroundImage, {backgroundImage: `url(${getImageUrl(data.background?.data?.attributes?.url)})`}]}>
          <Container disableGutters sx={conversationContainer}>
            <ModuleHeader
              title={data.title} 
              titleClass={headingSBluetec}
              subtitle={data.subtitle}
              subtitleClass={headingLBlack}
              description={data.description} 
              descriptionClass={bodyLeadBlack}
              buttonText={data.buttonText}
              contactClick={() => {handleContactClick()}}
            />
          </Container>
        </Container>
      } 
    </>
  );
}
