import { useState, useEffect } from "react"
import { Box, Grid, Typography, Container } from "@mui/material"
import { motion } from "framer-motion"

import Buttons from "../common/Buttons";
import technologyIcon from "../../assets/svg/technologyIcon.svg";

import { getImageUrl, mediaQueryBreakPoints } from "../../utils/functions";

import { buttonVerticalTextBlack, headingSBluetec, bodyTextBlack, 
  clientsItem, techImage, cardTechnology, mb20, mb40, 
  technologyImagesContainer, flexRow, alignItemsCenter} from "../../theme/styles";
import { expertiseAndTechnologyAnimation } from "../../utils/animations";

export default function Fields({ width, options }) {
  const [buttonStates, setButtonStates] = useState([true, false, false, false])
  const [boxText, setBoxText] = useState(options[0]?.description)
  const [boxTitle, setBoxTitle] = useState(options[0]?.title)
  const [boxImages, setBoxImages] = useState(options[0]?.technologies.data)
  const [buttonContainer, setButtonContainer] = useState({})
  
  const manageLines = (i) => {
    setButtonStates(options?.map((_, index) => (index === i)))
    setBoxText(options[i]?.description)
    setBoxTitle(options[i]?.title)
    setBoxImages(options[i]?.technologies.data)
  }

  useEffect (() => {
    if (width < mediaQueryBreakPoints.l) {
      setButtonContainer(flexRow)
    } else {
      setButtonContainer({})
    }
  }, [width])

  return (
    <Grid container columns={7}>
      {width > mediaQueryBreakPoints.xs ? 
        <>
        <Grid item l={3} s={12} xs={12} sx={buttonContainer}>
          {options?.map((data, index) => (
            data &&
            <Buttons 
              key={index}
              onClick={manageLines} 
              title={data.title} 
              index={index} 
              width={width} 
              state={buttonStates[index]} 
              textClass={buttonVerticalTextBlack}
            />
          ))}
        </Grid>
        <Grid item l={4}>
          <Box sx={cardTechnology}
            component={motion.div}
            layout
            transition={expertiseAndTechnologyAnimation}
          >
            <img alt={''} src={technologyIcon} />
            <Typography sx={[headingSBluetec, mb20]}>{boxTitle}</Typography>
            <Typography sx={[bodyTextBlack, mb20]}>{boxText}</Typography>
            <Box sx={[technologyImagesContainer]} >
              {boxImages.map((data, index) => (
                data.attributes &&
                <Box key={index} sx={clientsItem} >
                  <img 
                    alt={index} 
                    style={techImage} 
                    src={getImageUrl(data.attributes.url)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        </>
        :
        <Grid item l={4}>
          {options?.map((data, index) => {
            return (
              data &&
              <Box key={index} sx={[cardTechnology, mb40]}>
                <Container disableGutters sx={[flexRow, alignItemsCenter, mb20]}>
                  <img alt={''} src={technologyIcon} />
                  <Typography sx={[headingSBluetec]}>{data.title}</Typography>
                </Container>
                <Typography sx={[bodyTextBlack, mb20]}>{data.description}</Typography>
                <Box sx={[technologyImagesContainer]} >
                  {data.technologies?.data?.map((d, index) => (
                    d &&
                    <Box key={index} sx={clientsItem} >
                      <img 
                        alt={index} 
                        style={techImage} 
                        src={getImageUrl(d.attributes?.url)}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          })}
        </Grid>
      }
    </Grid>
  )
}
