import { Button, Typography } from "@mui/material"
import { Link } from "react-router-dom"

import { floatRight, buttonLink, mainButtonText } from "../../theme/styles"
import { useLanguageData } from "../../contexts/Contexts"
import { configureRoute } from "../../utils/functions"

export default function ButtonLink(props) {
  const { languageSelected } = useLanguageData()
  const route = configureRoute(props.buttonRoute, languageSelected)
  return (
    <div style={floatRight}>
      <Link to={props.buttonRoute && props.withFilter === true ? `${route}?filter=${props.name?props.name:'all'}` : route}>
        <Button sx={buttonLink} variant="contained">
          <Typography sx={mainButtonText}>{props.buttonText}</Typography>
          &nbsp;&nbsp;&nbsp;
          <Typography>{"\u2192"}</Typography>
        </Button>
      </Link>
    </div>
  )
}