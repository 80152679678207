import { Grid, Container } from "@mui/material"
import { useTheme } from "@emotion/react"

import { containerGrid, flexWrap, improvementsContainer } from "../../../../theme/styles"
import Improvement from "./Improvement"

export default function CaseImprovements({improvements}) {
  const theme = useTheme()
  return (
    <Container disableGutters maxWidth={false} sx={improvementsContainer}>
      <Grid container sx={[containerGrid]}>
        <Grid item l={3} xxs={3} xxxs={1}/>
        <Grid item l={9} xxs={6} xxxs={11}>
          <Container disableGutters maxWidth={false} sx={flexWrap}>
            {improvements?.map((imp, i) => {
              return (
                imp &&
                <Improvement
                  key={i}
                  improvement={imp}
                  theme={theme}
                  />
              )
            })}
          </Container>
        </Grid>
      </Grid>
    </Container>
  )
}