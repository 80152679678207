import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useEffect, useState, useRef } from "react";

import { backgroundImage, bodyLeadWhitec, caseContainer, 
  containerChallengeBanner, containerFitBlack, headingMWhitec, 
  headingSBluetec, mb40 } from "../../../../theme/styles";

import GridBG from "../../../common/GridBG";
import ModuleHeader from "../../../common/ModuleHeader";
import { getImageUrl } from "../../../../utils/functions";


export default function CaseChallenge({challenge, width}) {
  const [height, setHeight] = useState()
  const elementRef = useRef(null)
  const theme = useTheme()

  useEffect(() => {
    setHeight(elementRef.current.clientHeight)
  }, [])
  return (
    challenge &&
    <Container disableGutters maxWidth={false} sx={containerFitBlack} ref={elementRef} id={'challenge'}>
      <GridBG 
        width={width} 
        height={height ? height : 800} 
        color={theme.palette.bluetec20} 
        pattern={'void'} 
        />
      <Grid container sx={caseContainer}>
        <Grid item l={3} s={1}/>
        <Grid item l={9} s={11}>
          {challenge.image?.data && <Container disableGutters maxWidth={false} sx={[containerChallengeBanner, mb40, {background: `url(${getImageUrl(challenge.image?.data?.attributes?.url)})`}, backgroundImage]} />}
          <Container disableGutters maxWidth={false}>
            <ModuleHeader 
              title={challenge.sectionTitle} 
              titleClass={headingSBluetec}
              subtitle={challenge.title}
              subtitleClass={headingMWhitec}
              description={challenge.description}
              descriptionClass={bodyLeadWhitec}
            />
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}