import { Grid, Container } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState, useEffect, useRef } from "react";

import Solution from "./Solution";
import GridBG from "../../../common/GridBG";
import ModuleHeader from "../../../common/ModuleHeader";

import { containerGrid, mt40, mb60, headingLWhitec, bodyLeadWhitec, containerFitBluetec } from "../../../../theme/styles";

export default function CaseSolution({solution, width}) {
  const [height, setHeight] = useState()
  const elementRef = useRef(null)
  const theme = useTheme()

  useEffect(() => {
    setHeight(elementRef.current.clientHeight)
  }, [])

  return(
    <Container disableGutters maxWidth={false} ref={elementRef} sx={containerFitBluetec}  id={'solution'}>
      <GridBG 
        width={width} 
        height={height ? height : 1000} 
        color={theme.palette.whitec20} 
        pattern={'void'} 
        />
      <Grid container sx={[containerGrid, mt40]}>
        <Grid item l={3} s={1}/>
        <Grid item l={9} s={11}>
          <Container disableGutters maxWidth={false}>
            <ModuleHeader 
              title={solution?.title} 
              titleClass={headingLWhitec}
              subtitle={solution?.subtitle} 
              subtitleClass={bodyLeadWhitec}
            />
          </Container>
          <Grid container columns={9} sx={mb60}>
          {solution?.solutions?.map((s, i) => {
            return(
              s &&
              <Solution
                key={i}
                solution={s}
                isBig={s.isBig}
                theme={theme}
                />
            )
          })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}