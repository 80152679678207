import { useTheme } from "@emotion/react"
import { buttonLinkTextBlack, filterButton, filterMenu } from "../../theme/styles"
import { mediaQueryBreakPoints } from "../../utils/functions"
import { Button, Grid, MenuItem, Select, Typography } from "@mui/material"
import { useDragScroll } from "../../hooks/hooks"


export default function CaseFilter({expertise, width, filter, setFilter, setFilterFromDropDown, filterAll}) {
  const theme = useTheme()
  const { containerRef, isDragging } = useDragScroll();

  return (
    <>
    {width > mediaQueryBreakPoints.xs ? 
    <Grid container ref={containerRef} wrap='nowrap' sx={filterMenu}> 
      <Grid item>
        <Button sx={[filterButton, filter === 'all' && {background: theme.palette.whitecHover}]} onClick={() => {!isDragging && setFilter('all')}}>
          <Typography textTransform={'uppercase'} sx={buttonLinkTextBlack}>{filterAll}</Typography>
        </Button>
      </Grid>
      {expertise.data?.map((e, i) => {
        return (
          e.attributes &&
          <Grid key={i} item >
            <Button sx={[filterButton, filter === e.attributes.name && {background: theme.palette.whitecHover}]} onClick={() => {!isDragging && setFilter(e.attributes.name)}}>
              <Typography textTransform={'uppercase'} sx={buttonLinkTextBlack}>{e.attributes.name}</Typography>
            </Button>
          </Grid>
        )
      })}
    </Grid>
    : 
    <Select value={filter} onChange={setFilterFromDropDown} sx={{width: '100%', background: theme.palette.whitec}}>
      <MenuItem selected={true} sx={filterButton} value={'all'}>
        <Typography textTransform={'uppercase'} sx={buttonLinkTextBlack}>{filterAll}</Typography>
      </MenuItem>
      {expertise.data?.map((e, i) => {
        return (
          e.attributes &&
          <MenuItem key={i} sx={filterButton} value={e.attributes.name}>
            <Typography textTransform={'uppercase'} sx={buttonLinkTextBlack}>{e.attributes.name}</Typography>
          </MenuItem>
        )
      })}
    </Select>
    }
    </>
  )
}