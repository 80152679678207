import { Grid, Container } from "@mui/material";

import { backgroundImage, bodyLeadBlack, caseContainer, caseResultImage, 
  headingMBlack, headingSBluetec } from "../../../../theme/styles";
import ModuleHeader from "../../../common/ModuleHeader";
import { getImageUrl } from "../../../../utils/functions";


export default function CaseResults({result}) {
  return(
    <Grid container sx={caseContainer} id={'result'}>
      {result?.image?.data && <Container disableGutters maxWidth={false} sx={[caseResultImage, {background: `url(${getImageUrl(result?.image?.data?.attributes?.url)})`}, backgroundImage]} />}
      <Grid item l={3}/>
      <Grid item l={8}>
        <Container disableGutters maxWidth={false}>
          <ModuleHeader
            title={result?.sectionTitle}
            titleClass={headingSBluetec}
            subtitle={result?.title}
            subtitleClass={headingMBlack}
            description={result?.description}
            descriptionClass={bodyLeadBlack}
            />
        </Container>
      </Grid>
    </Grid>
  )
}