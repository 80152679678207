
import { useEffect, useRef, useState } from "react"
import { AnimatePresence } from "framer-motion"
import { Container, Grid } from "@mui/material"
import { useTheme } from "@emotion/react"

import GridBG from "../common/GridBG"
import CaseItem from "./CaseItem"
import { mediaQueryBreakPoints } from "../../utils/functions"

import { caseListContainerGrid, caseListView } from "../../theme/styles"
import CaseFilter from "./CaseFilter"
import { useFilterData } from "../../contexts/Contexts"
import { useLocation } from "react-router-dom"

export default function CaseList({data, width}) {
  const [finalHeight, setHeight] = useState(1024)
  const elementRef = useRef(null)
  const theme = useTheme()
  const {filter, setFilter} = useFilterData()
  const location = useLocation();

  useEffect(() => {
    setHeight(elementRef.current.offsetHeight);
  }, [])

  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterCriteria = searchParams.get('filter');
    if (filterCriteria) {
      setFilter(filterCriteria)
      window.scrollTo({ top: elementRef.current.parentNode.offsetTop, behavior: 'smooth' });
    }
  }, [location, setFilter, elementRef])
  
  const setFilterFromDropDown = (e) => {
    setFilter(e.target.value)
  }
  
  return(
    <>
      <GridBG width={width} height={finalHeight} color={theme.palette.bluetec20} pattern={'void'}/>
      <Container id='CaseList' ref={elementRef} disableGutters maxWidth={false} sx={caseListView}>
        <Grid container sx={caseListContainerGrid}>
        {
          data &&
          <Grid item l={12} sx={{width: '100%'}}>
            <CaseFilter expertise={data.expertise} 
              width={width} 
              filter={filter} 
              setFilter={setFilter}
              filterAll={data.filterAll}
              setFilterFromDropDown={setFilterFromDropDown}/>
            <Grid container columns={3} sx={width < mediaQueryBreakPoints.s ? {justifyContent: 'center'} : {}}> 
              <AnimatePresence>
                {data.cases?.data?.map((c, i) => {
                  return ((filter === 'all' || c.attributes?.expertise?.data?.attributes?.name === filter) &&
                    <CaseItem key={i} item={c} width={width}/>                    
                  )
                })}
              </AnimatePresence>
            </Grid>
          </Grid>
        }
        </Grid>
      </Container>
    </>
  )
}