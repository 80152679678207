import { Grid } from "@mui/material"

import Fields from "./Fields"
import GridBG from "../common/GridBG"
import ModuleHeader from "../common/ModuleHeader"

import { alignSelfFlexStart, containerGridReversed, headingLBlack, headingSBluetec } from "../../theme/styles"
import { useTheme } from "@emotion/react"

export default function Technology({ data, width, height }) {
  const theme = useTheme()
  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'technology'}/>
      {data &&
        <Grid container sx={containerGridReversed}>
        {data.technologies?.length > 0 &&
        <>
        <Grid sx={alignSelfFlexStart} item l={7}>
          <Fields width={width} options={data.technologies}/>
        </Grid>
        <Grid item l={1}/>
        </>
        }
        <Grid item l={4} sx={alignSelfFlexStart} >
          <ModuleHeader
            title={data.title} 
            titleClass={headingSBluetec}
            subtitle={data.subtitle} 
            subtitleClass={headingLBlack}
          />
        </Grid>
        </Grid>
      }
    </>
  )
}
