import { Box } from "@mui/material";
import { getImageUrl } from "../../utils/functions";
import { clientsContainer, clientsItem, clientsImage } from "../../theme/styles";
import { useDragScroll } from "../../hooks/hooks";

export default function Carousel({ array }) {
  const { containerRef, handleImageMouseDown } = useDragScroll();
  
  return (
    <Box sx={clientsContainer} ref={containerRef}>
      {array?.map((data, index) => (
        <Box key={index} sx={clientsItem}>
          <img 
            alt={data.attributes?.name} 
            style={clientsImage} 
            src={getImageUrl(data.attributes?.logo?.data?.attributes?.url)}
            onMouseDown={handleImageMouseDown}
          />
        </Box>
      ))}
    </Box>
  );
}