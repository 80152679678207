import { Grid, Container, Box } from "@mui/material";

import ModuleHeader from "../../../common/ModuleHeader";

import { getImageUrl } from "../../../../utils/functions";

import { headingSBluetec, headingMBlack, clientsItem, 
  flexRow, caseTechImage, mb60, caseContainer, flexWrap, 
  flexCenter } from "../../../../theme/styles";

export default function CaseTechnology({tech}) {
  return(
    tech &&
    <>
      <Grid container sx={caseContainer} id={'technology'}>
        <Grid item l={3} s={1}/>
        <Grid item l={8} s={11}>
          <Container disableGutters maxWidth={false}>
            <ModuleHeader 
              title={tech.sectionTitle} 
              titleClass={headingSBluetec}
              subtitle={tech.title}
              subtitleClass={headingMBlack}
            />
          </Container>
          <Container disableGutters sx={[flexRow, flexCenter, flexWrap, mb60, {gap: 5}]}>
            {tech.technologies?.data?.map((t, i) => {
              return (
                t &&
                <Box key={i} sx={clientsItem} >
                  <img 
                    alt={i} 
                    style={caseTechImage} 
                    src={getImageUrl(t.attributes?.url)}
                  />
                </Box>
              )
            })}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};