import { PopupVisibilityProvider, MenuOpenProvider, LanguageProvider, RoutesProvider, AppRefProvider, HelmetContextProvider, FilterProvider} from './contexts/Contexts';

import { useScrollToTop } from './hooks/hooks';

import RouteManager from './routes/RouteManager';

function App() {
  useScrollToTop()
  return (
    <MenuOpenProvider>
      <RoutesProvider>
        <LanguageProvider>
          <PopupVisibilityProvider> 
            <HelmetContextProvider>
              <AppRefProvider>
                <FilterProvider>
                  <RouteManager />
                </FilterProvider>
              </AppRefProvider>
            </HelmetContextProvider>
          </PopupVisibilityProvider>
        </LanguageProvider>
      </RoutesProvider>
    </MenuOpenProvider>
  );
}

export default App;
