import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import rightArrow from "../../assets/svg/rightArrow.svg"

import { mainButton, mainButtonText } from "../../theme/styles";
import { useLanguageData } from "../../contexts/Contexts";
import { configureRoute } from "../../utils/functions";

export default function MainButton(props) {
  const { languageSelected } = useLanguageData()
  const route = configureRoute(props.buttonRoute, languageSelected)
  return (
    <Link to={props.buttonRoute && route}>
      <Button onClick={props.contactClick} sx={mainButton} variant="contained">
        <Typography sx={mainButtonText}>{props.buttonText}</Typography>
        &nbsp;&nbsp;&nbsp;
        <img alt={props.buttonIcon} src={rightArrow} />
      </Button>
    </Link>
  );
}
