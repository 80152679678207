import { useState, useEffect, useRef } from "react";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../../../common/GridBG";
import Carousel from "../../../testimonials/Carousel";

import { containerMSWhitec, containerGrid } from "../../../../theme/styles";

export default function CaseClientTestimonials({client, width}) {
  const [height, setHeight] = useState()
  const elementRef = useRef(null)
  const theme = useTheme()

  useEffect(() => {
    setHeight(elementRef.curren?.clientHeight)
  }, [])
  return (
    <Container disableGutters maxWidth={false} ref={elementRef} sx={containerMSWhitec} id={'testimonial'}>
      <GridBG 
        width={width} 
        height={height ? height : 800} 
        color={theme.palette.bluetec20} 
        pattern={'testimonials'} 
        />
      <Grid container sx={containerGrid}>
        <Carousel array={client} from={'case'}/>
      </Grid>
    </Container>
  );
}