import { useState } from "react";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import Dropdown from "./Dropdown";
import GridBG from "../common/GridBG";
import ModuleHeader from "../common/ModuleHeader";

import { containerGrid, headingSBluetec, headingLBlack, bodyLeadBlack } from "../../theme/styles";

export default function Services({ data, width, height }) {
  const theme = useTheme()
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.bluetec20} pattern={'services'}/>
      <Grid container sx={containerGrid}>
        {data && 
          (
            <Grid item l={8}>
              <Container disableGutters maxWidth={false}>
                <ModuleHeader 
                  title={data.title} 
                  titleClass={headingSBluetec}
                  subtitle={data.subtitle} 
                  subtitleClass={headingLBlack}
                  description={data.description} 
                  descriptionClass={bodyLeadBlack}
                />
                {data.services?.map((s, i) => {
                  return (
                    s &&
                    <Dropdown 
                      key={i}
                      badge={s.badge} 
                      title={s.title} 
                      text={s.subtitle} 
                      textExpanded={s.description}
                      isOpen={openIndex === i}
                      onToggle={() => handleToggle(i)}
                    />
                  );
                })}
              </Container>
            </Grid>
          )
        }
      </Grid>
    </>
  );
}
