import { useEffect, useRef, useState } from "react"
import { Container, Grid } from "@mui/material"
import { useTheme } from "@emotion/react"

import GridBG from "../common/GridBG"
import ModuleHeader from "../common/ModuleHeader"
import Worker from "./Worker"

import { bodyLeadBlack, containerGrid, flexColumn, 
  headingLBlack, headingSBluetec, teamContainer } from "../../theme/styles"
import { usePopupVisibility } from "../../contexts/Contexts"

export default function Team({ data, width }) {
  const [finalHeight, setHeight] = useState(1024)
  const elementRef = useRef(null);
  const theme = useTheme()
  const context = usePopupVisibility()

  useEffect(() => {
    setHeight(elementRef.current.offsetHeight);
  }, [])

  const handleContactClick = () => {
    context.toggleContactPopup()
  }

  return (
    <>
      <GridBG width={width} height={finalHeight} color={theme.palette.bluetec20} pattern={'team'} />
      <Container ref={elementRef} disableGutters maxWidth={false} sx={flexColumn}>
        <Grid container sx={[containerGrid]}>
          {
            data &&
            <>
            <Grid item xs={8}>
              <ModuleHeader 
                title={data.title}
                titleClass={headingSBluetec}
                subtitle={data.subtitle}
                subtitleClass={headingLBlack}
                description={data.description}
                descriptionClass={bodyLeadBlack}
              />
            </Grid>
            <Container disableGutters maxWidth={false} sx={teamContainer}>
              <Grid container>
                {data.workers?.map((w, i) => {
                  return (
                    w &&
                    <Grid item l={4} xs={6} xxxs={12} key={i}>
                      <Worker
                        name={w.name}
                        job={w.job}
                        avatar={w.avatar?.data?.attributes?.url}
                        theme={theme}
                      />    
                    </Grid>
                  )
                })}
                <Grid onClick={()=>{handleContactClick()}} style={{cursor: 'pointer', zIndex: 4}} item l={4} xs={6} xxxs={12}>
                  <Worker
                    name={'Tú'}
                    job={'Envíanos tu CV'}
                    avatar={''}
                    theme={theme}
                  />  
                </Grid>
              </Grid>
            </Container>
            </>
          }
        </Grid>
      </Container>
    </>
  )
}