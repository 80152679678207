import { Container } from "@mui/material";
import { Link } from "react-router-dom";

import { configureRoute, getImageUrl } from "../../utils/functions";
import { menuLogo1, menuLogo2, menuLogoContainer, menuLogoWrapper } from "../../theme/styles";
import { useLanguageData } from "../../contexts/Contexts";

export default function Logo({ logo1, logo2 }) {
  const { languageSelected } = useLanguageData();
  return (
    <Container disableGutters={true} maxWidth={false} sx={menuLogoWrapper}>
      <Link to={configureRoute('/', languageSelected)}>
        <div style={menuLogoContainer}>
          {logo1 && <img style={menuLogo1} alt={logo1} src={getImageUrl(logo1)} />}
          {logo2 && <img style={menuLogo2} alt={logo2} src={getImageUrl(logo2)} />}
        </div>
      </Link>
    </Container>
  );
}
