import { useTheme } from "@emotion/react"

import bigOWhiteHero from '../../assets/svg/bigOwhiteHero.svg';
import { SvgRect, BigO } from "../common/SvgPaint"

export default function HeroSVG({mode, width, height}) {
  const theme = useTheme()
    const vpX = 0.75 * 6 * width / 12
    const vpY = height * 6 / 7 
    const columns = 5
    const rows = 6
    const patterns = {
      rect1: {
        styles: {
          0: {fill: theme.palette.white20, stroke: theme.palette.white},
          1: {fill: theme.palette.white20, stroke: theme.palette.white},
          2: {fill: theme.palette.white20, stroke: theme.palette.white},
          3: {fill: theme.palette.white20, stroke: theme.palette.white}
        },
        positions: {
          0: { x: 0, y: 0 },
          1: { x: 0, y: 0 },
          2: { x: 0, y: 1 },
          3: { x: 0, y: 0 }
        },
        shapes: {
          0: { width: 1, height: 2 },
          1: { width: 1, height: 2 },
          2: { width: 1, height: 2 },
          3: { width: 1, height: 2 }
        }
      },
      rect2: {
        styles: {
          0: {fill: theme.palette.white20, stroke: theme.palette.white},
          1: {fill: theme.palette.white20, stroke: theme.palette.white},
          2: {fill: theme.palette.white20, stroke: theme.palette.white},
          3: {fill: theme.palette.white20, stroke: theme.palette.white}
        },
        positions: {
          0: { x: 0, y: 4 },
          1: { x: 0, y: 4 },
          2: { x: 0, y: 4 },
          3: { x: 0, y: 4 }
        },
        shapes: {
          0: { width: 1, height: 1 },
          1: { width: 1, height: 1 },
          2: { width: 1, height: 1 },
          3: { width: 1, height: 1 }
        }
      },
      rect3: {
        styles: {
          0: {fill: theme.palette.white20, stroke: theme.palette.white},
          1: {fill: theme.palette.white20, stroke: theme.palette.white},
          2: {fill: theme.palette.white20, stroke: theme.palette.white},
          3: {fill: theme.palette.white20, stroke: theme.palette.white}
        },
        positions: {
          0: { x: 1, y: 2 },
          1: { x: 2, y: 1 },
          2: { x: 2, y: 3 },
          3: { x: 1, y: 2 }
        },
        shapes: {
          0: { width: 2, height: 2 },
          1: { width: 2, height: 2 },
          2: { width: 1, height: 2 },
          3: { width: 2, height: 2 }
        }
      },
      rect4: {
        styles: {
          0: {fill: theme.palette.white20, stroke: theme.palette.white},
          1: {fill: theme.palette.white20, stroke: theme.palette.white},
          2: {fill: theme.palette.white20, stroke: theme.palette.white},
          3: {fill: theme.palette.white20, stroke: theme.palette.white}
        },
        positions: {
          0: { x: 2, y: 0 },
          1: { x: 4, y: 0 },
          2: { x: 1, y: 0 },
          3: { x: 4, y: 0 }
        },
        shapes: {
          0: { width: 1, height: 1 },
          1: { width: 1, height: 1 },
          2: { width: 1, height: 1 },
          3: { width: 1, height: 1 }
        }
      },
      rect5: {
        styles: {
          0: {fill: theme.palette.white20, stroke: theme.palette.white},
          1: {fill: theme.palette.white20, stroke: theme.palette.white},
          2: {fill: theme.palette.white20, stroke: theme.palette.white},
          3: {fill: theme.palette.white20, stroke: theme.palette.white}
        },
        positions: {
          0: { x: 4, y: 3 },
          1: { x: 4, y: 3 },
          2: { x: 4, y: 2 },
          3: { x: 4, y: 3 }
        },
        shapes: {
          0: { width: 1, height: 2 },
          1: { width: 1, height: 2 },
          2: { width: 1, height: 3 },
          3: { width: 1, height: 2 }
        }
      },
      rect6: {
        styles: {
          0: {fill: theme.palette.white20, stroke: theme.palette.white},
          1: {fill: theme.palette.white20, stroke: theme.palette.white},
          2: {fill: theme.palette.white20, stroke: theme.palette.white},
          3: {fill: theme.palette.white20, stroke: theme.palette.white}
        },
        positions: {
          0: { x: 5, y: 2 },
          1: { x: 5, y: 2 },
          2: { x: 5, y: .5 },
          3: { x: 5, y: 2 }
        },
        shapes: {
          0: { width: 1, height: 1 },
          1: { width: 1, height: 1 },
          2: { width: 1, height: 1.5 },
          3: { width: 1, height: 1 }
        }
      },
      bigO: {
        positions: {
          0: {x: 3.5, y: .5},
          1: {x: 0.5, y: 2.5},
          2: {x: 2, y: 1},
          3: {x: 2.5, y: .5}
        },
        shape: {
          width: 1.5,
          height: 1.5
        }
      }
    }
    return (
      <svg style={{overflow: 'visible', position: "relative"}}  width={vpX} height={vpY} viewBox={`0 0 ${vpX} ${vpY}`} xmlns="http://www.w3.org/2000/svg">
        <SvgRect rect={patterns.rect1} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={mode}/>
        <SvgRect rect={patterns.rect2} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={mode}/>
        <SvgRect rect={patterns.rect3} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={mode}/>
        <SvgRect rect={patterns.rect4} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={mode}/>
        <SvgRect rect={patterns.rect5} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={mode}/>
        <SvgRect rect={patterns.rect6} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={mode}/>
        <BigO rect={patterns.bigO} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={mode} bigO={bigOWhiteHero}/>
      </svg>
    )
  }