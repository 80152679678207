import { Typography } from "@mui/material";
import Component from "../common/Component";

export default function NotFound() {
  return (
    <>
    <Typography>Not found</Typography>
    <Component component='conversation'/>
    <Component component='footer'/>
    </>
  )
}