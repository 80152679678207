import { motion, AnimatePresence } from "framer-motion"
import { SvgRect, BigO } from "../common/SvgPaint"
import { useTheme } from "@emotion/react"

export default function MetodologiaSVG({width, height, step, bigOWhite, bigOBlue, rectStart, rectHeight, gridColumns}) {
  const theme = useTheme()
    const vpX = 0.75 * 6 * width / gridColumns
    const vpY = height
    const columns = 6
    const rows = 6
    const steps = {
      rect1: {
        positions: {
            0: {x: 0, y: rectStart},
            1: {x: 0, y: rectStart},
            2: {x: 0, y: rectStart},
            3: {x: 0, y: rectStart},
            4: {x: 0, y: rectStart}
        },
        shapes: {
            0: {width: 1, height: rectHeight},
            1: {width: 1, height: rectHeight},
            2: {width: 1, height: rectHeight},
            3: {width: 1, height: rectHeight},
            4: {width: 1, height: rectHeight}
        },
        styles: {
          0: {stroke: theme.palette.transparent, fill: theme.palette.transparent},
          1: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          2: {stroke: theme.palette.whitec, fill: theme.palette.whitec20},
          3: {stroke: theme.palette.whitec, fill: theme.palette.whitec20},
          4: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20}
        }
      },
      rect2: {
        positions: {
            0: {x: 1, y: rectStart},
            1: {x: 1, y: rectStart},
            2: {x: 1, y: rectStart},
            3: {x: 1, y: rectStart},
            4: {x: 1, y: rectStart}
        },
        shapes: {
            0: {width: 1, height: rectHeight},
            1: {width: 1, height: rectHeight},
            2: {width: 1, height: rectHeight},
            3: {width: 1, height: rectHeight},
            4: {width: 1, height: rectHeight}
        },
        styles: {
          0: {stroke: theme.palette.transparent, fill: theme.palette.transparent},
          1: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          2: {stroke: theme.palette.whitec, fill: theme.palette.whitec20},
          3: {stroke: theme.palette.whitec, fill: theme.palette.whitec20},
          4: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20}
        }
      },
      rect3: {
        positions: {
            0: {x: 2, y: rectStart},
            1: {x: 2, y: rectStart},
            2: {x: 2, y: rectStart},
            3: {x: 2, y: rectStart},
            4: {x: 2, y: rectStart}
        },
        shapes: {
            0: {width: 1, height: rectHeight},
            1: {width: 1, height: rectHeight},
            2: {width: 1, height: rectHeight},
            3: {width: 1, height: rectHeight},
            4: {width: 1, height: rectHeight}
        },
        styles: {
          0: {stroke: theme.palette.transparent, fill: theme.palette.transparent},
          1: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          2: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          3: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          4: {stroke: theme.palette.bluetec20, fill: theme.palette.transparent}
        }
      },
      rect4: {
        positions: {
            0: {x: 3, y: rectStart},
            1: {x: 3, y: rectStart},
            2: {x: 3, y: rectStart},
            3: {x: 3, y: rectStart},
            4: {x: 3, y: rectStart}
        },
        shapes: {
            0: {width: 1, height: rectHeight},
            1: {width: 1, height: rectHeight},
            2: {width: 1, height: rectHeight},
            3: {width: 1, height: rectHeight},
            4: {width: 1, height: rectHeight}
        },
        styles: {
          0: {stroke: theme.palette.transparent, fill: theme.palette.transparent},
          1: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          2: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          3: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          4: {stroke: theme.palette.bluetec20, fill: theme.palette.transparent}
        }
      },
      rect5: {
        positions: {
            0: {x: 4, y: rectStart},
            1: {x: 4, y: rectStart},
            2: {x: 4, y: rectStart},
            3: {x: 4, y: rectStart},
            4: {x: 4, y: rectStart}
        },
        shapes: {
            0: {width: 1, height: rectHeight},
            1: {width: 1, height: rectHeight},
            2: {width: 1, height: rectHeight},
            3: {width: 1, height: rectHeight},
            4: {width: 1, height: rectHeight}
        },
        styles: {
          0: {stroke: theme.palette.transparent, fill: theme.palette.transparent},
          1: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          2: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          3: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          4: {stroke: theme.palette.bluetec20, fill: theme.palette.transparent}
        }
      },
      rect6: {
        positions: {
            0: {x: 5, y: rectStart},
            1: {x: 5, y: rectStart},
            2: {x: 5, y: rectStart},
            3: {x: 5, y: rectStart},
            4: {x: 5, y: rectStart}
        },
        shapes: {
            0: {width: 1, height: rectHeight},
            1: {width: 1, height: rectHeight},
            2: {width: 1, height: rectHeight},
            3: {width: 1, height: rectHeight},
            4: {width: 1, height: rectHeight}
        },
        styles: {
          0: {stroke: theme.palette.transparent, fill: theme.palette.transparent},
          1: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          2: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          3: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          4: {stroke: theme.palette.bluetec20, fill: theme.palette.transparent}
        }
      },
      rect: {
        positions: {
            0: {x: 0, y: rectStart},
            1: {x: 0, y: rectStart},
            2: {x: 0, y: rectStart},
            3: {x: 0, y: rectStart},
            4: {x: 0, y: rectStart}
        },
        shapes: {
            0: {width: 6, height: rectHeight},
            1: {width: 6, height: rectHeight},
            2: {width: 6, height: rectHeight},
            3: {width: 6, height: rectHeight},
            4: {width: 6, height: rectHeight}
        },
        styles: {
          0: {stroke: theme.palette.transparent, fill: theme.palette.transparent},
          1: {stroke: theme.palette.whitec40, fill: theme.palette.transparent},
          2: {stroke: theme.palette.whitec, fill: theme.palette.transparent},
          3: {stroke: theme.palette.whitec, fill: theme.palette.transparent},
          4: {stroke: theme.palette.bluetec, fill: theme.palette.bluetec20}
        }
      },
      bigO: {
        positions: {
          3: {x: 3.25, y: rectStart},
          4: {x: 3.25, y: rectStart}
        },
        shape: {
          width: 1.5,
          height: rectHeight
        }
      }
    } 
    return(
      <svg style={{overflow: 'visible'}} width={vpX} height={vpY} viewBox={`0 0 ${vpX} ${vpY}`} xmlns="http://www.w3.org/2000/svg">
        <SvgRect rect={steps.rect1} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
        <SvgRect rect={steps.rect2} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
        <SvgRect rect={steps.rect3} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
        <SvgRect rect={steps.rect4} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
        <SvgRect rect={steps.rect5} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
        <SvgRect rect={steps.rect6} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
        <SvgRect rect={steps.rect} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step}/>
        <AnimatePresence>
        {
          step === 3 &&
          <motion.svg initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{duration: .8}}>
            <BigO rect={steps.bigO} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step} bigO={bigOWhite}/>
          </motion.svg>
        }
         </AnimatePresence>
         <AnimatePresence>
        {
          step === 4 &&
          <motion.svg initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{duration: .8}}>
            <BigO rect={steps.bigO} vpX={vpX} vpY={vpY} columns={columns} rows={rows} position={step} bigO={bigOBlue}/>
          </motion.svg>
        } 
        </AnimatePresence>
      </svg>
    )
  } 