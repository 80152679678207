import { useEffect, useState } from "react"
import { Box, Container, Grid } from "@mui/material"
import { useTheme } from "@emotion/react"

import GridBG from "../common/GridBG"
import ModuleHeader from "../common/ModuleHeader"
import Menu from "../menu/Menu"
import HeroSVG from "./HeroSVG"

import rightChevronWhite from '../../assets/svg/rightChevronWhite.svg';
import leftChevronWhite from '../../assets/svg/leftChevronWhite.svg';

import { getImageUrl, mediaQueryBreakPoints } from "../../utils/functions"

import { 
  bodyLeadWhitec, containerGrid, headingXXLWhitec, 
  headingXXLBluetec, bodyLeadBluetec, containerMenuTransparent, 
  backgroundImage, heroArrowLayout, svgButton, alignSelfFlexStart 
} from "../../theme/styles"

export default function Hero({ data, width, height }) {
  const [position, setPosition] = useState(0)
  const [content, setContent] = useState()
  const theme = useTheme()

  const backPos = () => {
    if (position === 0) {
      setPosition(data.mainContent?.length-1)
    } else {
      setPosition(position - 1)
    }
  }
  
  const forPos = () => {
    if (position === data.mainContent?.length -1) {
      setPosition(0)
    } else {
      setPosition(position + 1)
    }
  }
  
  useEffect(()=> {
    setContent(data.mainContent[position])
  }, [position, data])
  
  return (
    <>
      <GridBG 
        width={width} 
        height={height} 
        color={theme.palette.white40} 
        pattern={'void'}
      />
      {data && content &&
        <Container 
          maxWidth={false} 
          disableGutters 
          sx={[backgroundImage, {backgroundImage: `url(${getImageUrl(content?.background.data?.attributes?.url)})`}]}>
          <Container disableGutters maxWidth={false} sx={containerMenuTransparent}>
            <Menu width={width} data={data.menu} theme={theme} option={'hero'} position={position} isDark={content?.isDark}/>
          </Container>
          <Container maxWidth={false} disableGutters sx={heroArrowLayout}>
            <Box onClick={backPos} sx={svgButton}>
              <img alt={''} src={leftChevronWhite} />
            </Box>
            <Grid container sx={containerGrid}>
              <Grid item l={5} s={5} sx={width < mediaQueryBreakPoints.xl ? alignSelfFlexStart : {}}>
                <ModuleHeader 
                  title={content?.title} 
                  titleClass={content?.isDark ? headingXXLBluetec : headingXXLWhitec}
                  description={content?.description} 
                  descriptionClass={content?.isDark ? bodyLeadBluetec : bodyLeadWhitec}
                />
              </Grid>
              <Grid item l={1} s={1}/>
              {width > mediaQueryBreakPoints.s &&
                <Grid item l={6} s={6}>
                  <Container disableGutters maxWidth={false}>
                    <HeroSVG 
                      mode={position} 
                      width={width} 
                      height={width > mediaQueryBreakPoints.xl ? height : 700} 
                    />
                  </Container>
                </Grid>
              }
            </Grid>
            <Box onClick={forPos} sx={svgButton}>
              <img alt={''} src={rightChevronWhite} />
            </Box>
          </Container>
        </Container>
      }
    </>
  )
}
