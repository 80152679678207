import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../common/GridBG";
import ModuleHeader from "../common/ModuleHeader";
import Expertise from "./Expertise";
import bigOBlue from "../../assets/svg/bigOBlue.svg";

import { containerGrid, alignSelfFlexStart, headingSBluetec, headingLWhitec, bodyLeadWhitec, mb60 } from "../../theme/styles";
import { mediaQueryBreakPoints } from "../../utils/functions";

export default function Verticals({ data, width, height }) {
  const theme = useTheme()
  return (
    <>
      {data && 
        <>
          <GridBG 
            width={width} 
            height={height} 
            color={theme.palette.white20} 
            pattern={'verticals'} 
            patternImage={bigOBlue}
          />
          <Grid container sx={containerGrid}>
            <Grid item l={4} s={7} sx={[alignSelfFlexStart, mb60]}>
              <ModuleHeader 
                title={data.title} 
                titleClass={headingSBluetec}
                subtitle={data.subtitle} 
                subtitleClass={headingLWhitec}
                description={data.description} 
                descriptionClass={bodyLeadWhitec}
                buttonText={data.buttonLeftText}
                buttonRoute={data.buttonLeftRoute}
              />
            </Grid>
            <Grid item l={1} s={0}/>
            <Grid sx={[alignSelfFlexStart, width > mediaQueryBreakPoints.l ? {} : {width: '100%'}]} item l={7}>
              {data.expertise?.data?.length > 0 &&
              <Expertise 
                width={width} 
                btnOptions={data.expertise?.data}
                buttonText={data.buttonRightText}
                buttonRoute={data.buttonRightRoute}
              />}
            </Grid>
          </Grid>
        </>
      }
    </>
  );
};
