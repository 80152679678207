import { Chip } from "@mui/material"
import { useTheme } from "@emotion/react"

import { bodyTextXS, bodyTextXSBluetecBold, mb20 } from "../../theme/styles"

export default function CaseChip({caseType, isBig, label}) {
  const theme = useTheme()
  
  const types = {
    L: theme.palette.purpletec,
    T: theme.palette.greentec,
    N: theme.palette.yellowtec,
    C: theme.palette.bluetec20,
    Link: theme.palette.purpletec,
    Tip: theme.palette.greentec,
    News: theme.palette.yellowtec,
    Case: theme.palette.bluetec20
  }

  const styleClass = {
    width: isBig ? 'fit-content' : '40px', 
    border: `1px solid ${theme.palette.bluetec}`, 
    color: theme.palette.bluetec, 
    background: types[caseType]
  }

  return <Chip sx={[isBig ? bodyTextXSBluetecBold : bodyTextXS, styleClass, isBig && mb20]} label={label}/>
}