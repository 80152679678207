import { useEffect, useState } from "react";
import { Button, Container, Grid, Typography} from "@mui/material";
import { useTheme } from "@emotion/react";

import GridBG from "../common/GridBG";
import Menu from "../menu/Menu";
import ModuleHeader from "../common/ModuleHeader";

import { getImageUrl } from "../../utils/functions";
import rightArrow from "../../assets/svg/rightArrow.svg";
import heroUs from "../../assets/svg/heroUs.svg";
import heroExp from "../../assets/svg/heroExperience.svg";

import { usePopupVisibility } from "../../contexts/Contexts";

import { alignSelfFlexStart, backgroundImage, bodyLeadBlack, bodyLeadWhitec, 
  containerGrid, containerMenuWhitec, flexColumn, headingLBlack, headingLWhitec, 
  heroSectionContent, mainButton, mainButtonText } from "../../theme/styles";

export default function HeroSection({ data, width, height, from }) {
  const [sectionData, setSectionData] = useState()
  const theme = useTheme()
  const context = usePopupVisibility()
  
  useEffect(() => {
    setSectionData(data.sectionContent?.filter(item => item.identifier === from)[0])
  }, [data, from])

  const handleContactClick = () => {
    context.toggleContactPopup()
  }

  return (
    <>
      <GridBG width={width} height={height} color={theme.palette.white20} pattern={'void'} />
      {data && sectionData && 
        <Container 
          maxWidth={false} 
          disableGutters 
          sx={[flexColumn, backgroundImage, {backgroundImage: `url(${getImageUrl(sectionData?.background?.data?.attributes?.url)})`}]}>
          <Container disableGutters maxWidth={false} sx={containerMenuWhitec}>
            <Menu width={width} data={data.menu} option={from} theme={theme} isDark={sectionData?.isDark}/>
          </Container>
          <Container disableGutters maxWidth={false} sx={heroSectionContent}>
            <Grid container sx={[containerGrid, alignSelfFlexStart]}>
              <Grid item lg={8}>
                <ModuleHeader
                  icon={from === 'us' ? heroUs : heroExp}
                  title={sectionData?.title} 
                  titleClass={sectionData?.isDark ? headingLBlack : headingLWhitec}
                  description={sectionData?.description} 
                  descriptionClass={sectionData?.isDark ? bodyLeadBlack : bodyLeadWhitec}
                />
                {sectionData?.buttonText &&
                  <Button onClick={handleContactClick} sx={mainButton} variant="contained">
                    <Typography sx={mainButtonText}>{sectionData?.buttonText}</Typography>
                    &nbsp;&nbsp;&nbsp;
                    <img alt={''} src={rightArrow} />
                  </Button>
                }
              </Grid>
            </Grid>
          </Container>
        </Container>
      }
    </>
  );
}