import Verticals from './verticals/Verticals';
import Hero from './hero/Hero';
import Clients from './clients/Clients';
import Footer from './footer/Footer';
import Adn from './adn/Adn';
import About from './about/About';
import Services from './services/Services';
import Technology from './technology/Technology';
import Methodology from './methodology/Methodology';
import HeroSection from './heroSection/HeroSection';
import Team from './team/Team';
import Testimonials from './testimonials/Testimonials';
import CaseStudies from './caseStudies/CaseStudies';
import Offices from './offices/Offices';
import Relation from './relation/Relation';
import Conversation from './conversation/Conversation';
import CaseList from './caseList/CaseList';

// Iremos guardando los diferentes componentes para que Component renderize el que corresponda en cada caso
export const allComponents = {
  hero: Hero,
  heroSection: HeroSection,
  verticals: Verticals,
  clients: Clients,
  conversation: Conversation,
  footer: Footer,
  about: About,
  adn: Adn,
  services: Services,
  technology: Technology,
  methodology: Methodology,
  offices: Offices,
  team: Team,
  relation: Relation,
  testimonials: Testimonials,
  caseStudies: CaseStudies,
  caseList: CaseList
}