import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Container } from '@mui/material';

import { componentSizeClass, getStrapiEndpoints } from '../../utils/functions';
import { useStrapiSingle } from '../../hooks/hooks';

import { allComponents } from '../index';

export default function Component({ component, from, filterCriteria }) {
  const [data, setData] = useState(null);
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  const elementRef = useRef(null);

  const useWindowSize  = () => {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([elementRef.current.offsetWidth, elementRef.current.offsetHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize(elementRef)

  useEffect(() => {
    setSize([elementRef.current.offsetWidth, elementRef.current.offsetHeight]);
  }, [data])

  const dataStrapi = useStrapiSingle(
    getStrapiEndpoints(component).map((e) => {
      return e.replace('###identifier###', from);
    })
  );

  useEffect(() => {
    setData(dataStrapi);
  }, [dataStrapi]);

  const ComponentSelected = allComponents[component];

  return (
    <Container disableGutters ref={elementRef} maxWidth={false} sx={componentSizeClass(component)}>
      {data && 
        <ComponentSelected data={data} width={width} height={height} from={from} elementRef={elementRef} filterCriteria={filterCriteria} />
      }
    </Container>
  );
}
