import { Button, Typography, Container, Box } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"

import { configureRoute, getImageUrl } from "../../utils/functions"
import rightArrowWhite from '../../assets/svg/rightArrow.svg';
import rightArrowBlue from '../../assets/svg/rightArrowBlue.svg';

import { bodyLWhitec, bodyTextWhitec, buttonLinkCases, caseImage, caseItem, caseItemLink } from "../../theme/styles"
import { useLanguageData } from "../../contexts/Contexts";

export default function CaseItem({ c, onMouseDown }) {
	const [hover, setHover] = useState(false)
	const { languageSelected } = useLanguageData()
	return (
		<div onMouseDown={onMouseDown} style={caseItemLink}>
			{ c && c.attributes &&
			<Box sx={caseItem}>
				<Container disableGutters sx={[caseImage, {backgroundImage: `url(${getImageUrl(c.attributes.thumbnail?.data?.attributes?.url)})`}]}/>
				<Typography sx={bodyLWhitec}>{c.attributes.client?.data?.attributes?.name}</Typography>
				<Container disableGutters sx={{height: 200, overflow: 'hidden'}}>
					<Typography sx={bodyTextWhitec}>{c.attributes.client?.data?.attributes?.description}</Typography>
				</Container>
				<Button component={Link} 
				to={configureRoute(`/${c.attributes.case_type?.data?.attributes?.name.toLowerCase().replace(/\s/g, '')}/${c.attributes.identifier}`, languageSelected)} 
				onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}
				sx={buttonLinkCases} 
				variant="contained">
					<img alt={''} src={hover ? rightArrowBlue : rightArrowWhite} />
				</Button>
			</Box>
			}
		</div>
	);
}