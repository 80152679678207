import { useState, useEffect } from "react";

import { useStrapiSingle } from "../../hooks/hooks";

import { componentSelector, getStrapiEndpoints } from "../../utils/functions";

export default function View({name, from}) {
  const [data, setData] = useState(null);

  const dataStrapi = useStrapiSingle(
    getStrapiEndpoints(from).map((e) => {
      return e.replace('###identifier###', name);
    })
  );

  useEffect(() => {
    setData(dataStrapi);
  }, [dataStrapi]);

  const ViewSelected = componentSelector[from]
  
  return (
    <>
    {data && 
      <ViewSelected data={data}/>
    }
    </>
  );
}