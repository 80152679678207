import { Container, Grid } from "@mui/material";
import { Button, Typography} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

import ModuleHeader from "../../../common/ModuleHeader";
import CaseChip from "../../../common/CaseChip";
import SubMenu from "./SubMenu";

import { headingSBluetec, headingLBlack, bodyLeadBlack, caseContainer, floatRight, buttonLink, mainButtonText } from "../../../../theme/styles";
import GridBG from "../../../common/GridBG";
import { mediaQueryBreakPoints } from "../../../../utils/functions";


export default function CaseIntroduction({ data, expertise, routes, from, width, height }) {
  const theme = useTheme()
  return (
    <>
        {data && 
          (
          <Grid container sx={caseContainer} id={'introduction'}>
            {from === 'Case' ?
            width > mediaQueryBreakPoints.s && 
              <>
              <Grid item xxs={2}>
                <SubMenu routes={routes}/>
              </Grid>
              <Grid item xxs={1}/>
              </>
            :
              <GridBG 
                width={width} 
                height={height} 
                color={theme.palette.bluetec20} 
                pattern={'void'} 
                />
            }
            <Grid item l={9} s={9} xxs={12}>
              <Container disableGutters maxWidth={false}>
                <CaseChip 
                  theme={theme} 
                  caseType={from} 
                  label={expertise?.name} 
                  isBig={true}/>
                <ModuleHeader 
                  title={data?.name} 
                  titleClass={headingSBluetec}
                  subtitle={data?.subtitle} 
                  subtitleClass={headingLBlack}
                  description={data?.description} 
                  descriptionClass={bodyLeadBlack}
                />
              </Container>
            </Grid>
            {from === 'Case' && width < mediaQueryBreakPoints.s &&
              <SubMenu routes={routes}/>
            }
            {from ==='Link' &&
              <Grid item xxs={12}>
                  <div style={floatRight}>
                    <Link to={data?.web} target={'_blank'}>
                      <Button sx={[buttonLink, {color: theme.palette.bluetec}]} variant="contained">
                        <Typography sx={mainButtonText}>{data?.buttonWebText}</Typography>
                        &nbsp;&nbsp;&nbsp;
                        <Typography>{"\u2192"}</Typography>
                      </Button>
                    </Link>
                  </div>
              </Grid>
            }
          </Grid>
          )
        }
    </>
  );
}
