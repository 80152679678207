import { Container, Typography } from "@mui/material"

import { getImageUrl, mediaQueryBreakPoints } from "../../utils/functions"

import { bodyLeadBluetec, bodyTextBluetec, mb20, officeImage, officeFrame, addressWrapper, locationsContainer, mb40 } from "../../theme/styles"

export default function Location({location, width}) {
  return (
    location.attributes &&
    <Container disableGutters sx={locationsContainer}>
      {width > mediaQueryBreakPoints.m &&
        <Container disableGutters sx={[officeImage, {  backgroundImage: `url(${getImageUrl(location.attributes.image?.data?.attributes?.url)})`}]}/>
      }
      <Container disableGutters sx={addressWrapper}>
        <div style={mb20}>
          <Typography sx={[bodyLeadBluetec, width > mediaQueryBreakPoints.m && mb20]}>{location.attributes.city}</Typography>
          <Typography sx={bodyTextBluetec} >{location.attributes.address}</Typography>
          <Typography sx={bodyTextBluetec} >{location.attributes.phone}</Typography>
        </div>
        {width < mediaQueryBreakPoints.m &&
          <Container disableGutters sx={[officeImage, mb40, {  backgroundImage: `url(${getImageUrl(location.attributes.image?.data?.attributes?.url)})`}]}/>
        }
        <div style={officeFrame}>
          <iframe 
            title={location.attributes.city}
            width="100%"
            height="100%" 
            frameBorder="0" 
            src={location.attributes.mapsFrame}>
            <a href="https://www.gps.ie/">gps devices</a>
          </iframe>
        </div>
      </Container>
    </Container>
  )
}