import { useEffect, useState, useRef } from "react"
import { useHelmetData, useLanguageData } from "../contexts/Contexts";
import { localeFallback } from "../utils/functions";
import { useLocation } from "react-router-dom";

const useStrapiSingle = (endpoint) => {
  const [data, setData] = useState(null);
  const url = `${process.env.REACT_APP_STRAPI_BASE_URL}${endpoint[0]}`
  const url2 = endpoint.length > 1 ? `${process.env.REACT_APP_STRAPI_BASE_URL}${endpoint[1]}` : null
  const localeContext = useLanguageData()
  const lang = localeContext ? localeContext.languageSelected : 'es'

  useEffect(() => {
    const fetchData = () => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          let res = result.data
          if (url2) {
            fetch(url2)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then((result) => {
              res = localeFallback(res, lang)
              res['menu'] = localeFallback(result.data, lang)
              setData(res)
            })
            .catch((error) => {
              throw error
            });
          } else {
            setData(localeFallback(res, lang))
          }
        })
        .catch((error) => {
          throw error
        });
    };
    fetchData();
  }, [url, url2, lang]);

  return data;
}

const useStrapiLocale = (endpoint) => {
  const [data, setData] = useState(null);
  const url = `${process.env.REACT_APP_STRAPI_BASE_URL}${endpoint}`

  useEffect(() => {
    const fetchData = () => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          setData(result)
        })
        .catch((error) => {
          throw error
        });
    };
    fetchData();
  }, [url]);

  return data;
}

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const useSeo = (props) => {
  const context = useHelmetData()
  useEffect(() => { 
    context.setSeoTitle(props.title)
    context.setSeoDescription(props.description)
    context.setCanonicalUrl(props.url)
    context.setKeywords(props.keywords)
    context.setRobots(props.robots)
    context.setViewport(props.viewport)
    context.setMetaImage(props.image)
 }, [context, props])
}

const useDragScroll = () => {
  const [isDragging, setIsDragging] = useState(false)
  const containerRef = useRef(null);
  const isDown = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const velocity = useRef(0);
  const animationFrame = useRef(null);
  const dragTimeout = useRef(null);

  useEffect(() => {
    const handleMouseDown = (e) => {
      e.preventDefault();
      isDown.current = true;
      startX.current = e.pageX - containerRef.current.offsetLeft;
      scrollLeft.current = containerRef.current.scrollLeft;
      velocity.current = 0;

      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }

      dragTimeout.current = setTimeout(() => {
        if (isDown.current) {
          setIsDragging(true);
        }
      }, 500);

    };
    const handleMouseUp = () => {
      clearTimeout(dragTimeout.current);
      
      setTimeout(() => {
        isDown.current = false;
        setIsDragging(false)
      }, 50)
    };

    const handleMouseMove = (e) => {
      if (!isDown.current) return;
      e.preventDefault();
      const x = e.pageX - containerRef.current.offsetLeft;
      const walk = x - startX.current;
      containerRef.current.scrollLeft = scrollLeft.current - walk;
      velocity.current = walk;
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        container.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  const handleImageMouseDown = (e) => {
    e.preventDefault();
  };

  return { containerRef, handleImageMouseDown, isDragging };
}

export { useStrapiSingle, useStrapiLocale, useScrollToTop, useDragScroll, useSeo }