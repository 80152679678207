import { Container, Typography, Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from "@emotion/react";

import Logo from "./Logo";
import Links from "./Links";
import radioDefaultWhite from '../../assets/svg/radioDefaultWhite.svg';
import radioDefaultBlue from '../../assets/svg/radioDefaultBlue.svg';
import radioHoverWhite from '../../assets/svg/radioHoverWhite.svg';
import radioHoverBlue from '../../assets/svg/radioHoverBlue.svg';

import { configureRoute, mediaQueryBreakPoints } from "../../utils/functions";

import { 
  alignItemsCenter,
  headingLWhitec,
  menuButtonText, menuContainer, menuLink, 
  menuLinksContainer, menuLocaleContainer 
} from "../../theme/styles";
import { useLanguageData, useMenuOpenData } from "../../contexts/Contexts";
import MobileMenu from "./MobileMenu";
import { useLocation } from "react-router-dom";

export default function Menu({ width, data, option, isDark }) {
  const theme = useTheme()

  const menuContext = useMenuOpenData()
  const languageContext = useLanguageData()
  const location = useLocation()

  let logo1, logo2, radioDefault, radioHover;

  if (data) {
    if (option === 'hero') {
      logo1 = isDark ? data.logoBlue1 : data.logoWhite1;
      logo2 = isDark ? data.logoBlue2 : data.logoWhite2;
      radioDefault = isDark ? radioDefaultWhite : radioDefaultBlue;
      radioHover = isDark ? radioHoverWhite : radioHoverBlue;
      menuButtonText.color = isDark ? theme.palette.whitec : theme.palette.bluetec;
    } else {
      logo1 = data.logoBlue1;
      logo2 = data.logoBlue2;
      radioDefault = radioDefaultBlue;
      radioHover = radioHoverBlue;
      menuButtonText.color = theme.palette.bluetec;
    }
  } else {
    logo1 = null;
  }

  return (
    <>
      {data && (
        <>
        <MobileMenu 
          data={data} 
          radioDefault={radioDefault}
          radioHover={radioHover}
          option={option}
          isDark={isDark}
          textClass={menuButtonText}
          handleLocale={languageContext.handleSetLocale}
          logo={isDark ? data.logoWhite2?.data?.attributes?.url : data.logoBlue2?.data?.attributes?.url}
          />
        <Container disableGutters maxWidth={false} sx={menuContainer}>
          <Logo logo1={width > mediaQueryBreakPoints.xxs ? logo1?.data?.attributes?.url : null} logo2={logo2?.data?.attributes?.url} />
          {width > mediaQueryBreakPoints.s ? 
          <>
            <Container disableGutters maxWidth={false} sx={menuLinksContainer}>
              {data.routes?.data?.map((route, i) => (
                route?.attributes &&
                <Links
                  key={i}
                  to={route.attributes.route}
                  name={route.attributes.name}
                  isActive={location.pathname === configureRoute(route.attributes.route, languageContext.languageSelected)}
                  textClass={menuButtonText}
                  color={option === 'hero' ? !isDark ? theme.palette.whitec : theme.palette.bluetec : menuButtonText.color}
                  radioDefault={option === 'hero' ? !isDark ? radioDefaultWhite : radioDefaultBlue : radioDefault}
                  radioHover={option === 'hero' ? !isDark ? radioHoverWhite : radioHoverBlue : radioHover}
                />
              ))}
            </Container>
            <Container disableGutters maxWidth={false} sx={menuLocaleContainer}>
              {languageContext.locales &&
                languageContext.locales.map((l, i) => (
                  <Container disableGutters onClick={() => {languageContext.handleSetLocale(l.code)}} key={i} sx={[{cursor: 'pointer'}, menuLink]}>
                    <Typography sx={[menuButtonText, {color: option === 'hero' ? !isDark ? theme.palette.whitec : theme.palette.bluetec : menuButtonText.color}]}>
                      <span style={l.code === languageContext.languageSelected ? {fontWeight: 700} : {}}>
                        {l.code}
                      </span>
                    </Typography>
                  </Container>
                ))
              }
            </Container>
          </>
          :
            <Button onClick={() => {menuContext.toggleMenu()}} sx={[alignItemsCenter, {width: 'fit-content', margin: '0 0 0 10px'}]}>
              <MenuIcon sx={[headingLWhitec, {fontSize: '48px !important', color: option === 'hero' ? isDark ? theme.palette.bluetec : theme.palette.whitec : theme.palette.bluetec}]}/>
            </Button>
          }
        </Container>
        </>
      )}
    </>
  );
}