import Component from '../components/common/Component';
import { useParams } from 'react-router-dom';

import View from '../components/common/View';

export default function CaseCompleto() {
  const params = useParams()
  return(
		<>
      <View name={params.caseName} from='caseView'/>
      <Component component='conversation'/>
      <Component component='footer'/>
		</>
	)
}