import { Container, Button, Typography } from "@mui/material"

import { caseButtonBullet, bodyTextBlack, navigateButton, caseIntroductionSubmenu } from "../../../../theme/styles"

export default function SubMenu({routes}) {
  return (
    <Container disableGutters sx={caseIntroductionSubmenu}>
      {routes?.map((r, i) => {
        return (
          r && r.attributes &&
          <Button key={i} href={r.attributes.identifier} sx={navigateButton}>
            <div style={caseButtonBullet}/>
            <Typography sx={bodyTextBlack}>{r.attributes.name}</Typography>
          </Button>
        )
      })}
    </Container>
  )
}