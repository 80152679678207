import { Container, Typography } from "@mui/material";

import { mb20, mb40 } from "../../theme/styles";

import MainButton from "./MainButton";

export default function ModuleHeader(props) {
  return (
    <Container disableGutters maxWidth={false}>
      {props.icon && <img alt={''} style={{width: '60px'}} src={props.icon}/>}
      {props.title && <Typography key={props.title} sx={[props.titleClass, mb20]}>{props.title}</Typography>}
      {props.subtitle && <Typography key={props.subtitle} sx={[props.subtitleClass, mb40]}>{props.subtitle}</Typography>}
      {props.description && <Typography key={props.description} sx={[props.descriptionClass, mb40]}>{props.description}</Typography>}
      {props.buttonText && <MainButton contactClick={props.contactClick ? props.contactClick : ()=>{}} buttonRoute={props.buttonRoute} buttonText={props.buttonText}/>}
    </Container>
  );
}