import { useState } from "react";
import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { mb10, menuLink, menuLinksRadius } from "../../theme/styles";
import { configureRoute } from "../../utils/functions";
import { useLanguageData } from "../../contexts/Contexts";

export default function Links({ to, isActive, textClass, radioDefault, radioHover, name, color }) {
  const [hovered, setHovered] = useState(isActive);
  const { languageSelected } = useLanguageData();

  const setHover = () => {
    setHovered(true);
  };

  const setNotHover = () => {
    setHovered(isActive ? true : false);
  };

  return (
    <Link style={menuLink} 
      onMouseOver={setHover}
      onMouseOut={setNotHover} to={configureRoute(to, languageSelected)}>
      <Typography sx={[textClass, mb10, {color: color}]}>{name}</Typography>
      <Container sx={[menuLinksRadius, {backgroundImage: `url(${hovered ? radioHover : radioDefault})`}]}/>
    </Link>
  );
}
